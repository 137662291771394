import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { PowerModal } from '@src/components/modals/power/power.modal';
import { ArlenorLevel } from '@src/models/arlenor/ArlenorLevel';
import { ArlenorPower, PowerRanksEnum } from '@src/models/arlenor/ArlenorPower';
import { ModelEnum } from '@src/models/ModelEnum';

@Component({
  selector: 'character-powers-selection',
  templateUrl: './character-powers-selection.component.html',
  styleUrls: ['./character-powers-selection.component.scss'],
})
export class CharacterPowersSelectionComponent implements OnInit, OnChanges {
  @Input() public indexCrystal: number;
  @Input() public level: ArlenorLevel;
  @Input() public idsPowers: number[];
  @Input() public powers: ArlenorPower[];
  @Output() public outAdd = new EventEmitter<ArlenorPower>();
  @Output() public outRemove = new EventEmitter<ArlenorPower>();

  public ranks: ModelEnum[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public currentRanks: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public rankPowers: any;

  constructor(public dialog: MatDialog) {}

  public ngOnInit(): void {
    this.updateRanks();
  }

  public ngOnChanges(): void {
    this.updateRanks();
  }

  public updateRanks() {
    if (!this.powers) return;
    this.ranks = this.powers.map(power => power.rank).filter((value, index, categoryArray) => categoryArray.indexOf(value) === index);
    this.ranks.sort((a, b) => b.Code.localeCompare(a.Code));
    this.rankPowers = {
      [PowerRanksEnum.Commun.Code]: this._getPowersByRank(PowerRanksEnum.Commun.Code),
      [PowerRanksEnum.Rare.Code]: this._getPowersByRank(PowerRanksEnum.Rare.Code),
      [PowerRanksEnum.TresRare.Code]: this._getPowersByRank(PowerRanksEnum.TresRare.Code),
    };
    this.currentRanks = {
      [PowerRanksEnum.Commun.Code]: { current: this._getNbRank(PowerRanksEnum.Commun.Code), max: this.level.maxRankC[this.indexCrystal] },
      [PowerRanksEnum.Rare.Code]: { current: this._getNbRank(PowerRanksEnum.Rare.Code), max: this.level.maxRankB[this.indexCrystal] },
      [PowerRanksEnum.TresRare.Code]: { current: this._getNbRank(PowerRanksEnum.TresRare.Code), max: this.level.maxRankA[this.indexCrystal] },
      [PowerRanksEnum.Unique.Code]: { current: this._getNbRank(PowerRanksEnum.Unique.Code), max: this.level.maxRankS[this.indexCrystal] },
    };
  }

  public checkPower(power: ArlenorPower) {
    return this.idsPowers.includes(power.id);
  }

  public changePower(power: ArlenorPower) {
    if (!this.checkPower(power)) this.outAdd.emit(power);
    else this.outRemove.emit(power);
  }

  public checkDisabled(codeRank: string, power: ArlenorPower) {
    if (this.checkPower(power)) return false;
    return this.currentRanks[codeRank].current >= this.currentRanks[codeRank].max;
  }

  public seeMore(power: ArlenorPower) {
    this.dialog.open(PowerModal, {
      data: power,
    });
  }

  private _getPowersByRank(rank: string) {
    return this.powers.filter(power => power.codeRank === rank);
  }

  private _getNbRank(rank: string) {
    return this.powers.filter(power => power.codeRank === rank && this.idsPowers.includes(power.id)).length;
  }
}
