import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationModal } from '@src/components/modals/confirmation/confirmation.modal';
import { ArlenorBestiary } from '@src/models/arlenor/ArlenorBestiary';
import { ArlenorCreature } from '@src/models/arlenor/ArlenorCreature';
import { ArlenorPower } from '@src/models/arlenor/ArlenorPower';
import { getListBestiaries } from '@src/models/data/ListBestiaries';
import { DifficultyEnum, ModelEnum } from '@src/models/ModelEnum';
import { StoreService } from '@src/services/store.service';
import downloads_creatures from '@src/utils/downloads_creatures';

@Component({
  selector: 'creature-selection-view',
  templateUrl: './creature-selection.view.html',
  styleUrls: ['./creature-selection.view.scss'],
})
export class CreatureSelectionView implements OnInit {
  @Output() public outReset = new EventEmitter();
  @Output() public outCreate = new EventEmitter();

  public form: FormGroup;
  public allBestiaries: ArlenorBestiary[];
  public allDifficulties: ModelEnum[];

  private _destroyRef = inject(DestroyRef);

  public get selectedCreature(): ArlenorCreature | null {
    if (this.form.controls.guid) {
      const selectedCreature = this.filteredCreatures.find(creature => creature.guid === this.form.controls.guid.value);
      return selectedCreature ? selectedCreature : null;
    } else return null;
  }

  public get needsToBeDeleted(): boolean {
    return this.selectedCreature && !this.selectedCreature.isVersionOK;
  }

  public get creatures(): ArlenorCreature[] {
    const allCreatures = this._storeService.$allCreatures.value || [];
    const localCreatures = this._storeService.localCreatures || [];
    return allCreatures.concat(localCreatures);
  }

  public get filteredCreatures(): ArlenorCreature[] {
    let creatures = this.creatures.slice();
    if (this.form.controls.codeBestiary.value) creatures = creatures.filter(creature => creature.codeBestiary === this.form.controls.codeBestiary.value);
    return creatures;
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  public get selectedBestiaryName() {
    const codeBestiary = this.form.controls.codeBestiary.value;
    if (codeBestiary) return this.allBestiaries.find(bestiary => bestiary.code === codeBestiary).name;
    else return 'Tous les types';
  }

  public get selectedDifficultyName() {
    const codeDifficulty = this.form.controls.codeDifficulty.value;
    if (codeDifficulty) return this.allDifficulties.find(diff => diff.Code === codeDifficulty).Libelle;
    else return 'Toutes les difficultés';
  }

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      guid: new FormControl(null, Validators.required),
      codeBestiary: new FormControl(''),
      codeDifficulty: new FormControl(''),
    });
  }

  public ngOnInit() {
    this._storeService.loadAllCreatures(true);
    this._storeService.loadLocalCreatures();

    this.allBestiaries = getListBestiaries();
    this.allDifficulties = [DifficultyEnum.Simple, DifficultyEnum.Standard, DifficultyEnum.Complexe, DifficultyEnum.Epique, DifficultyEnum.Legendaire];

    this.form.controls.codeBestiary.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.form.controls.guid.setValue(null);
    });

    this.form.controls.codeDifficulty.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.form.controls.guid.setValue(null);
    });
  }

  public getDetails(creature: ArlenorCreature) {
    if (creature.isVersionOK) {
      let libelle = creature.bestiary?.name;
      if (!creature.isBDD) libelle += ' (' + creature.date + ' - ' + creature.hour + ')';
      return libelle;
    } else {
      return '(ancienne version à supprimer)';
    }
  }

  public getColor(codeDifficulty: string) {
    if (codeDifficulty === DifficultyEnum.Simple.Code) return 'status-ocean';
    else if (codeDifficulty === DifficultyEnum.Standard.Code) return 'status-green';
    else if (codeDifficulty === DifficultyEnum.Complexe.Code) return 'status-yellow';
    else if (codeDifficulty === DifficultyEnum.Epique.Code) return 'status-red';
    else if (codeDifficulty === DifficultyEnum.Legendaire.Code) return 'status-violet';
    else return '';
  }

  public create() {
    if (this.form.controls.guid.value) {
      this._storeService.changeCreature(this.selectedCreature);
      this._storeService.resetGUIDCreature();
    } else this._storeService.resetCreature();
    this.outCreate.emit();
  }

  public reset() {
    this.outReset.emit();
  }

  public selectCreature(guid: string) {
    this.form.controls.guid.setValue(guid);
  }

  public async download(isColored: boolean) {
    downloads_creatures.downloadPDF(isColored, this.selectedCreature, this.allPowers);
  }

  public openDeletePopup() {
    const dialogRef = this.dialog.open(ConfirmationModal, {
      data: {
        title: `Suppression de la créature`,
        content: `Souhaitez-vous vraiment supprimer la créature nommée <b>${this.selectedCreature.name}</b> ?<br><br>Cette action est irréversible.`,
      },
    });

    dialogRef.afterClosed().subscribe((value: boolean) => {
      if (value) {
        this._storeService.deleteLocalCreature(this.selectedCreature.guid);
        this._storeService.resetCreature();
        this.form.controls.guid.setValue('');
      }
    });
  }
}
