<div class="character-intro">
  <div class="card character-intro-card mod-clickable" (click)="startCreate()">
    <img
      class="character-intro-img"
      src="assets/images_filled/system/character-intro_ld.webp"
      alt="Illustration d'un personnage de JDR"
    />
    <div class="character-intro-content">
      <i class="character-intro-icon icon-user-plus"></i>
      <h2 class="u-marginBottom05">Créer</h2>
      <h3 class="u-marginBottom1">Un nouveau personnage</h3>
      <p class="u-marginBottom1" [innerHtml]="characterCreateText"></p>
      <button type="button" mat-stroked-button color="primary">
        Lancer la création
        <i class="icon-arrow-right"></i>
      </button>
    </div>
  </div>

  <div class="card character-intro-card mod-clickable" (click)="startSelect()">
    <img
      class="character-intro-img"
      src="assets/images_filled/system/character-final_ld.webp"
      alt="Illustration de plusieurs personnages de JDR"
    />
    <div class="character-intro-content">
      <i class="character-intro-icon icon-users"></i>
      <h2 class="u-marginBottom05">Sélectionner</h2>
      <h3 class="u-marginBottom1">Un personnage existant</h3>
      <p class="u-marginBottom1" [innerHtml]="characterSelectText"></p>
      <button type="button" mat-stroked-button color="primary">
        Voir la sélection
        <i class="icon-arrow-right"></i>
      </button>
    </div>
  </div>
</div>

<div class="card u-marginTop1">
  <p class="u-textCenter">Si vous souhaitez remplir à la main, des fiches vierges sont disponibles.</p>
  <div class="u-flexRow u-flexWrap u-justifyCenter u-marginTop1">
    <button type="button" mat-stroked-button (click)="download(false)">
      Télécharger la fiche<br />(en noir et blanc)
    </button>
    <button type="button" mat-stroked-button color="primary" (click)="download(true)">
      Télécharger la fiche<br />(en couleurs)
    </button>
  </div>
</div>
