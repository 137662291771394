import { Component, EventEmitter, Output } from '@angular/core';

import { convertMarkdownToHTML } from '@src/utils/conversions';
import downloads_creatures from '@src/utils/downloads_creatures';

@Component({
  selector: 'creature-intro-view',
  templateUrl: './creature-intro.view.html',
  styleUrls: ['./creature-intro.view.scss'],
})
export class CreatureIntroView {
  @Output() public outCreate = new EventEmitter();
  @Output() public outSelect = new EventEmitter();

  public creatureCreateText = creatureCreateText;
  public creatureSelectText = creatureSelectText;

  public startCreate() {
    this.outCreate.emit();
  }

  public startSelect() {
    this.outSelect.emit();
  }

  public async download(isColored: boolean) {
    downloads_creatures.downloadPDFVide(isColored);
  }
}

export const creatureCreateText = convertMarkdownToHTML(`
  Une créature est définie par plusieurs choses : on commence par **son type**,
  **ses caractéristiques**, **ses capacités**... pour ensuite finir par **son identité** et quelques finitions.`);

export const creatureSelectText = convertMarkdownToHTML(`
  Des **créatures pré-tirés** vous seront présentées : vous pourrez les garder tels quels ou les modifier. Cependant
  c'est **VOUS** qui le ferez vivre à travers **ses actions et/ou ses combats**.`);
