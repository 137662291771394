import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { DivinityModal } from '@src/components/modals/divinity/divinity.modal';
import { ArlenorCharacter } from '@src/models/arlenor/ArlenorCharacter';
import { ArlenorDivinity } from '@src/models/arlenor/ArlenorDivinity';
import { getListDivinites } from '@src/models/data/ListDivinities';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'character-divinity',
  templateUrl: './character-divinity.component.html',
  styleUrls: ['./character-divinity.component.scss'],
})
export class CharacterDivinityComponent implements OnInit {
  @Output() public outCheck = new EventEmitter<boolean>();

  public form: FormGroup;
  public character: ArlenorCharacter;

  public allDivinities: ArlenorDivinity[];
  public points: number[] = [3, 2, 1, 0];

  private _destroyRef = inject(DestroyRef);

  public get currentDivinity(): ArlenorDivinity | null {
    const divinity = this.allDivinities.find(divinity => divinity.code === this.form.controls.codeDivinity.value);
    return divinity ? divinity : null;
  }

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      codeDivinity: new FormControl(null),
      divinityPoints: new FormControl(0),
      deathPoints: new FormControl(0),
    });
  }

  public ngOnInit() {
    this.character = this._storeService.character;
    this.allDivinities = getListDivinites();

    // Init
    this.form.controls.codeDivinity.setValue(this.character.codeDivinity);
    this.form.controls.divinityPoints.setValue(this.character.divinityPoints);
    this.form.controls.deathPoints.setValue(this.character.deathPoints);

    this.outCheck.emit(!this.form.invalid);
    this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this._storeService.modifyCharacter();
      this.outCheck.emit(!this.form.invalid);
      this._save();
    });
  }

  public getPalier(value: number) {
    if (value) {
      let result = 'Palier ';
      for (let i = 0; i < value; i++) {
        result += 'I';
      }
      return result;
    } else return 'Aucun';
  }

  public openDivinityPopup() {
    this.dialog.open(DivinityModal, {
      data: this.currentDivinity ? this.currentDivinity : null,
    });
  }

  public openDivinityURL() {
    const url = this._router.serializeUrl(this._router.createUrlTree(['univers/religion']));
    window.open(url, '_blank');
  }

  private _save() {
    const newCharacter = new ArlenorCharacter();
    newCharacter.codeDivinity = this.form.controls.codeDivinity.value;
    newCharacter.divinityPoints = this.form.controls.divinityPoints.value;
    newCharacter.deathPoints = this.form.controls.deathPoints.value;
    this._storeService.changeCharacterDivinity(newCharacter);
  }
}
