import jsPDF from 'jspdf';

import { ArlenorCreature } from '../models/arlenor/ArlenorCreature';
import { ArlenorPower } from '../models/arlenor/ArlenorPower';

const fontSize = 10; // en px
const fontSizeMin = 8; // en px

// Le PDF a4: [595.28, 841.89] en pt [446.46, 631.4175] en px
// L'image est en [1400, 1980] en px
const conv = (val: number) => {
  return val / (1400 / 446.46);
};

const convFont = (val: number, isMin = false) => {
  return (val + (isMin ? fontSizeMin : fontSize)) / (1400 / 446.46);
};

const downloadPDF = async (isColored: boolean, creature: ArlenorCreature, allPowers: ArlenorPower[]): Promise<void> => {
  const doc = new jsPDF('p', 'px', [conv(1400), conv(1980)], true);
  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();

  const backgroundRecto = isColored ? 'assets/files/FicheCreature_Colo.jpeg' : 'assets/files/FicheCreature.jpeg';
  doc.addImage(backgroundRecto, 'JPEG', 0, 0, width, height);
  doc.setFontSize(fontSize);
  if (isColored) doc.setTextColor(255, 255, 255);

  // --- AVATAR ET IDENTITE
  if (creature.avatar) doc.addImage(creature.profil, 'JPEG', conv(35), conv(35), conv(310), conv(310));
  doc.text('' + creature.name, conv(700), convFont(255), { align: 'center' });
  doc.text('' + (creature.caractMag ? 'Oui' : 'Non'), conv(605), convFont(330), { align: 'center' });
  doc.text('' + creature.bestiary?.name + ' (' + creature.getDifficultyLibelle(allPowers) + ')', conv(980), convFont(330), { align: 'center' });

  // --- CARACTERISTIQUES ET VALEURS DERIVES
  const lineH = 75;
  let x = 355;
  let y = 480;
  doc.text('' + creature.caractFor, conv(x), convFont(y), { align: 'center' });
  y += lineH;
  doc.text('' + creature.caractHab, conv(x), convFont(y), { align: 'center' });
  y += lineH;
  doc.text('' + creature.caractInt, conv(x), convFont(y), { align: 'center' });
  y += lineH;
  doc.text('' + creature.caractTen, conv(x), convFont(y), { align: 'center' });
  y += lineH;
  doc.text('' + creature.caractCha, conv(x), convFont(y), { align: 'center' });
  y += lineH;
  doc.text('' + creature.caractMag, conv(x), convFont(y), { align: 'center' });
  y += lineH * 2;
  doc.text('' + creature.healthMax, conv(x), convFont(y), { align: 'center' });
  y += lineH;
  doc.text('' + creature.initiative, conv(x), convFont(y), { align: 'center' });

  // --- PROFIL
  x = 1045; // 1055
  y = 405;
  doc.setFontSize(fontSizeMin);
  y += lineH;
  doc.text('' + creature.description, conv(x), convFont(y, true));
  y += lineH;
  doc.text('' + creature.traits, conv(x), convFont(y, true));

  x = 755;
  y = 700;
  doc.text('' + checkLibelle(creature.story, 740, true), conv(x), convFont(y, true), {
    align: 'left',
    maxWidth: 175,
  });

  // --- POUVOIRS
  const size = 56;
  const powers = allPowers.filter(power => creature.idsPowers.includes(power.id));

  x = 155;
  y = 1230;
  for (let i = 0; i < 5; i++) {
    const power = powers[i];
    if (power) {
      const title = checkLibelle(power.name + ' (' + power.type.Libelle + ')', 40);
      const subtitle =
        checkLibelle(power.category.Libelle, 6, true) +
        ' - ' +
        checkLibelle(power.range.Libelle, 6, true) +
        ' - ' +
        checkLibelle(power.duration.Libelle, 6, true);
      const description = checkLibelle(power.description, 185);

      if (power.image) doc.addImage(power.image, 'JPEG', conv(x - 75 - size / 2), conv(y - size / 2), conv(size), conv(size));
      doc.text(title, conv(x), convFont(y - lineH / 5, true));
      doc.text(subtitle, conv(x), convFont(y + lineH / 5, true));
      doc.text(description, conv(x + 450), convFont(y - lineH / 5), {
        align: 'left',
        maxWidth: 225,
      });
    }
    y += lineH;
  }

  doc.save('Arlenor_' + getName(creature.name) + '.pdf');
};

const downloadPDFVide = async (isColored: boolean): Promise<void> => {
  const doc = new jsPDF('p', 'px', [conv(1400), conv(1980)], true);
  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();

  const backgroundRecto = isColored ? 'assets/files/FicheCreature_Colo.jpeg' : 'assets/files/FicheCreature.jpeg';
  doc.addImage(backgroundRecto, 'JPEG', 0, 0, width, height);
  doc.save('Arlenor_Vide.pdf');
};

const checkLibelle = (value: string, max: number, onlyPoint = false) => {
  let newValue: string;
  if (value.length <= max - (onlyPoint ? 1 : 3)) newValue = value;
  else newValue = value.slice(0, max - (onlyPoint ? 1 : 3)) + (onlyPoint ? '.' : '...');
  return newValue.replace('\n', ' ');
};

const getName = (value: string) => {
  let code = value;
  code = code.normalize('NFD').replace(/\p{Diacritic}/gu, '');
  code = code.replace(/\s/g, '');
  return code.charAt(0).toUpperCase() + code.slice(1);
};

export default {
  downloadPDF: downloadPDF,
  downloadPDFVide: downloadPDFVide,
};
