<h2 class="u-textCenter u-marginBottom2">Choix dans la liste</h2>

<div class="card u-width100">
  <form class="u-flexRow u-flexColumn-on-tablet" [formGroup]="form">
    <mat-form-field>
      <mat-label>Type de créature</mat-label>
      <mat-select formControlName="codeBestiary">
        <mat-select-trigger>{{ selectedBestiaryName }}</mat-select-trigger>
        <mat-option [value]="''">Tous les types</mat-option>
        <mat-option *ngFor="let bestiary of allBestiaries" [value]="bestiary.code">
          {{ bestiary.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Difficulté</mat-label>
      <mat-select formControlName="codeDifficulty">
        <mat-select-trigger>{{ selectedDifficultyName }}</mat-select-trigger>
        <mat-option [value]="''">Toutes les difficultés</mat-option>
        <mat-option *ngFor="let difficulty of allDifficulties" [value]="difficulty.Code">
          <span [class]="getColor(difficulty.Code)">{{ difficulty.Libelle }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <div *ngIf="!filteredCreatures.length" class="creature-noselection">
    Aucune créature sélectionnable avec les filtres actuels.
  </div>
  <div *ngIf="filteredCreatures.length" class="creature-selection">
    <div
      *ngFor="let creature of filteredCreatures"
      class="creature-selection-item"
      [class.is-selected]="selectedCreature?.guid === creature.guid"
      (click)="selectCreature(creature.guid)"
    >
      <img class="creature-selection-img" [src]="creature.profil" [alt]="creature.name" />
      <div class="creature-selection-text">
        <div>{{ creature.name }}</div>
        <div class="creature-selection-subtitle">{{ getDetails(creature) }}</div>
        <span [class]="creature.getDifficultyColor(allPowers)">{{ creature.getDifficultyLibelle(allPowers) }}</span>
      </div>
    </div>
  </div>
</div>

<div class="u-flexRow u-flexWrap u-justifyCenter u-marginTop2">
  <button type="button" mat-stroked-button (click)="reset()">
    <i class="icon-arrow-left"></i>
    Retour
  </button>
  <button
    type="button"
    mat-stroked-button
    color="primary"
    [disabled]="!form.valid || needsToBeDeleted"
    (click)="create()"
  >
    Modifier la créature
  </button>
</div>

@if (selectedCreature) {
  <div class="u-flexRow u-alignStart u-flexColumn-on-tablet u-alignCenter-on-tablet u-marginTop2">
    @if (!needsToBeDeleted) {
      <div class="card">
        <p class="u-textCenter">
          Vous pouvez télécharger la fiche créature de <b>{{ selectedCreature.name }}</b
          >.
        </p>
        <div class="u-flexRow u-flexWrap u-justifyCenter u-marginTop1">
          <button type="button" mat-stroked-button [disabled]="needsToBeDeleted" (click)="download(false)">
            Télécharger sa fiche<br />(en noir et blanc)
          </button>
          <button
            type="button"
            mat-stroked-button
            color="primary"
            [disabled]="needsToBeDeleted"
            (click)="download(true)"
          >
            Télécharger sa fiche<br />(en couleurs)
          </button>
        </div>
      </div>
    }

    @if (!selectedCreature.isBDD) {
      <div class="card" [class.is-warning]="needsToBeDeleted">
        <p class="u-textCenter">
          Votre créature <b>{{ selectedCreature.name }}</b> peut être supprimée de la liste, car elle est sauvegardée
          localement.
          <span *ngIf="needsToBeDeleted"><br />Sa version est ancienne, devenue incompatible.</span>
        </p>
        <div class="u-flexRow u-justifyCenter u-marginTop1">
          <button type="button" mat-stroked-button color="warn" (click)="openDeletePopup()">
            Supprimer la créature
          </button>
        </div>
      </div>
    }
  </div>
}
