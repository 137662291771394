import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { ArlenorBestiary } from '@src/models/arlenor/ArlenorBestiary';
import { ArlenorCreature } from '@src/models/arlenor/ArlenorCreature';
import { ArlenorPower } from '@src/models/arlenor/ArlenorPower';
import { getListBestiaries } from '@src/models/data/ListBestiaries';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'encyclo-bestiary-view',
  templateUrl: './encyclo-bestiary.view.html',
  styleUrls: ['./encyclo-bestiary.view.scss'],
})
export class EncycloBestiaryView implements OnInit, OnChanges {
  @Input() public indexBestiary: number;

  public get allCreatures(): ArlenorCreature[] {
    const allCreatures = this._storeService.$allCreatures.value || [];
    const localCreatures = this._storeService.localCreatures || [];
    return allCreatures.concat(localCreatures);
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  public bestiary: ArlenorBestiary;
  public creatures: ArlenorCreature[];

  constructor(private _storeService: StoreService) {}

  public ngOnInit() {
    this._storeService.loadAllPowers();
    this._storeService.loadAllCreatures();
    this._storeService.loadLocalCreatures();
    this._refresh();
  }

  public ngOnChanges() {
    this._refresh();
  }

  private _refresh() {
    const allBestiary = getListBestiaries();
    this.bestiary = allBestiary[this.indexBestiary];
    this.creatures = this.allCreatures.filter(creature => creature.codeBestiary === this.bestiary.code);
  }
}
