import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationModal } from '../../../components/modals/confirmation/confirmation.modal';
import { PowerFormModal } from '../../../components/modals/power-form/power-form.modal';
import { ArlenorArchetype } from '../../../models/arlenor/ArlenorArchetype';
import { ArlenorPower } from '../../../models/arlenor/ArlenorPower';
import { ArlenorSkill } from '../../../models/arlenor/ArlenorSkill';
import { ArlenorSpeciality } from '../../../models/arlenor/ArlenorSpeciality';
import { ArlenorSpecialities } from '../../../models/data/ListSpecialities';
import { ModelEnum } from '../../../models/ModelEnum';
import { PageTitles } from '../../../models/PagesTitles';
import { StoreService } from '../../../services/store.service';
import { convertMarkdownToHTML } from '../../../utils/conversions';
import supabase_api from '../../../utils/supabase_api';

@Component({
  selector: 'crystals-view',
  templateUrl: './crystals.view.html',
  styleUrls: ['./crystals.view.scss'],
})
export class CrystalsView implements OnInit {
  public invocateurText = invocateurText;

  public title = PageTitles.crystals;
  public allArchetypes: ArlenorArchetype[];
  public allPowers: ArlenorPower[];
  public allSkills: ArlenorSkill[];

  public codeSpe: string;
  public currentSpeciality: ArlenorSpeciality;
  public allSpecialities: ArlenorSpeciality[];
  public specialityPowers: ArlenorPower[];
  public selectedPower: ArlenorPower;
  public ranks: ModelEnum[];

  private _destroyRef = inject(DestroyRef);

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  constructor(
    public dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _storeService: StoreService
  ) {}

  public ngOnInit() {
    this._activatedRoute.queryParams.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(params => {
      this.codeSpe = params.spe;
      if (!this.codeSpe) this.moveToSpe('GARDIEN');
      else this._updatePage();
    });

    this._storeService.$allPowers.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(value => {
      if (value) this.allPowers = value;
      this._updatePage();
    });

    this._storeService.$allSkills.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(value => {
      if (value) this.allSkills = value;
      this._updatePage();
    });

    this._storeService.$allArchetypes.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(value => {
      if (value) this.allArchetypes = value;
      this._updatePage();
    });

    this._storeService.loadAllPowers();
    this._storeService.loadAllSkills();
    this._storeService.loadAllArchetypes();
    this.allSpecialities = ArlenorSpecialities.getListSpecialities();
  }

  public moveToSpe(code: string) {
    this._router.navigate([], {
      queryParams: { spe: code },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });

    this.codeSpe = code;
    this._updatePage();
  }

  public selectPower(power: ArlenorPower | null) {
    this.selectedPower = power;
  }

  public openAddPower() {
    const dialogRef = this.dialog.open(PowerFormModal, {
      data: {
        title: `Ajout d'un pouvoir`,
        codeSpe: this.codeSpe,
        power: new ArlenorPower(),
      },
    });

    dialogRef.afterClosed().subscribe(async (value: ArlenorPower) => {
      if (value) {
        const result = await supabase_api.postPower(value);
        if (result) this._storeService.loadAllPowers(true);
        // Les données de la page se rechargent automatiquement
      }
    });
  }

  public openModifyPower() {
    const dialogRef = this.dialog.open(PowerFormModal, {
      data: {
        title: `Modification du pouvoir`,
        codeSpe: this.codeSpe,
        power: this.selectedPower,
      },
    });

    dialogRef.afterClosed().subscribe(async (value: ArlenorPower) => {
      if (value) {
        const result = await supabase_api.putPower(value);
        if (result) this._storeService.loadAllPowers(true);
        // Les données de la page se rechargent automatiquement
      }
    });
  }

  public openDeletePower() {
    const dialogRef = this.dialog.open(ConfirmationModal, {
      data: {
        title: `Suppression du pouvoir`,
        content: `Vous allez supprimer "` + this.selectedPower.name + `" et c'est irréversible.`,
      },
    });

    dialogRef.afterClosed().subscribe(async (value: boolean) => {
      if (value) {
        const result = await supabase_api.deletePower(this.selectedPower);
        if (result) this._storeService.loadAllPowers(true);
        // Les données de la page se rechargent automatiquement
      }
    });
  }

  public getRankPowers(rank: ModelEnum): ArlenorPower[] {
    const powers = this.specialityPowers.filter(power => power.rank.Code === rank.Code);
    powers.sort((a, b) => b.rank.Code.localeCompare(a.rank.Code));
    return powers;
  }

  private _updatePage() {
    if (this.codeSpe) {
      const targetSpeciality = ArlenorSpecialities.getListSpecialities().find(spe => spe.code === this.codeSpe);
      this.currentSpeciality = targetSpeciality ? targetSpeciality : null;
      this.selectPower(null);
    } else {
      this.currentSpeciality = null;
    }

    if (this.currentSpeciality && this.allArchetypes && this.allSkills) {
      // Archetypes
      const archetypes = this.allArchetypes.filter(arch => arch.codeSpeciality === this.currentSpeciality?.code);
      if (this.currentSpeciality && archetypes.length > 0) {
        this.currentSpeciality.archetype01 = archetypes[0];
        const skill = this.allSkills.find(skill => skill.id === this.currentSpeciality?.archetype01?.idSkill);
        if (skill) this.currentSpeciality.archetype01.skill = skill;
      }
      if (this.currentSpeciality && archetypes.length > 1) {
        this.currentSpeciality.archetype02 = archetypes[1];
        const skill = this.allSkills.find(skill => skill.id === this.currentSpeciality?.archetype02?.idSkill);
        if (skill) this.currentSpeciality.archetype02.skill = skill;
      }
    }

    if (this.currentSpeciality && this.allPowers) {
      // Pouvoirs
      const spe = this.currentSpeciality;
      const list = spe.code ? this.allPowers.filter(power => power.speciality && power.speciality.code === spe.code) : [];
      list.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      this.specialityPowers = list;
      this.ranks = this.specialityPowers.map(power => power.rank).filter((value, index, categoryArray) => categoryArray.indexOf(value) === index);
      this.ranks.sort((a, b) => b.Code.localeCompare(a.Code));
    } else {
      this.specialityPowers = [];
      this.ranks = [];
    }
  }
}

export const invocateurText = convertMarkdownToHTML(`
Les invocateurs sont des mages ayant la possibilité de faire appel à des invocations et les contrôler.

- Les invocations sont forcément des créatures : un pseudo-esprit est alors créé via l'imaginaire sous une forme matérielle.
- L'invocateur peut communiquer avec toutes les invocations, et donner des ordres aux siennes.
- L'invocation a tout de même sa propre personnalité et sa propre mémoire.
- Si l'invocation meurt, l'invocateur devra invoquer une toute nouvelle créature.`);
