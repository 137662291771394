import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationModal } from '@src/components/modals/confirmation/confirmation.modal';
import { InformationModal } from '@src/components/modals/information/information.modal';
import { ArlenorCreature } from '@src/models/arlenor/ArlenorCreature';
import { ArlenorPower } from '@src/models/arlenor/ArlenorPower';
import { ArlenorSkill } from '@src/models/arlenor/ArlenorSkill';
import { StoreService } from '@src/services/store.service';
import downloads_creatures from '@src/utils/downloads_creatures';
import random from '@src/utils/random';
import supabase_api from '@src/utils/supabase_api';

@Component({
  selector: 'creature-final-view',
  templateUrl: './creature-final.view.html',
  styleUrls: ['./creature-final.view.scss'],
})
export class CreatureFinalView implements OnInit {
  @Output() public outBack = new EventEmitter();
  @Output() public outReset = new EventEmitter();

  public creature: ArlenorCreature;
  public isLoading: boolean;
  public isSaved: boolean;
  public isSavedBDD: boolean;

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  public get allSkills(): ArlenorSkill[] {
    return this._storeService.$allSkills.value || [];
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {}

  public ngOnInit() {
    this.creature = this._storeService.creature;
    this.isLoading = true;
    this.isSaved = false;
    this.isSavedBDD = false;
  }

  public end() {
    this.isLoading = false;
  }

  public back() {
    if (!this.isSaved) {
      const dialogRef = this.dialog.open(ConfirmationModal, {
        data: {
          title: `Changement de page`,
          content: `Vous allez quitter la page : les modifications ne seront pas enregistrées.`,
        },
      });

      dialogRef.afterClosed().subscribe((value: boolean) => {
        if (value) this.outBack.emit();
      });
    } else this.outBack.emit();
  }

  public reset() {
    this.outReset.emit();
  }

  public async download(isColored: boolean) {
    downloads_creatures.downloadPDF(isColored, this.creature, this.allPowers);
  }

  public openSavePopup() {
    const creature = this.creature;
    creature.initTime();
    creature.isBDD = false;
    this._storeService.saveLocalCreature(creature);
    this.isSaved = true;

    this.dialog.open(InformationModal, {
      data: {
        title: `Sauvegarde de la créature`,
        content: `Votre créature <b>${creature.name}</b> est désormais sauvegardée sur votre navigateur.<br><br>
        Note : En effaçant l'historique de votre navigateur, vos créatures sauvegardées seront effacées.`,
      },
    });
  }

  public async openSavePopupBDD() {
    const creature = this.creature;
    creature.initTime();
    creature.isBDD = true;
    creature.guid = random.generateID(20);
    await supabase_api.postCreature(creature);
    this.isSavedBDD = true;

    this.dialog.open(InformationModal, {
      data: {
        title: `Sauvegarde du créature`,
        content: `Votre créature <b>${creature.name}</b> est désormais sauvegardée en base de données.`,
      },
    });
  }
}
