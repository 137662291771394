import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { RaceModal } from '@src/components/modals/race/race.modal';
import { ArlenorBestiary } from '@src/models/arlenor/ArlenorBestiary';
import { ArlenorCreature } from '@src/models/arlenor/ArlenorCreature';
import { getListBestiaries } from '@src/models/data/ListBestiaries';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'creature-bestiary',
  templateUrl: './creature-bestiary.component.html',
  styles: [':host {	display: contents; }'],
})
export class CreatureBestiaryComponent implements OnInit {
  @Output() public outCheck = new EventEmitter<boolean>();

  public form: FormGroup;
  public allBestiaries: ArlenorBestiary[];

  private _destroyRef = inject(DestroyRef);

  public get currentBestiary(): ArlenorBestiary | null {
    const bestiary = this.allBestiaries.find(best => best.code === this.form.controls.codeBestiary.value);
    return bestiary ? bestiary : null;
  }

  public get selectedBestiaryName() {
    const codeBestiary = this.form.controls.codeBestiary.value;
    if (codeBestiary) return this.allBestiaries.find(bestiary => bestiary.code === codeBestiary).name;
    else return '-';
  }

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      codeBestiary: new FormControl(null, Validators.required),
    });
  }

  public ngOnInit() {
    this.allBestiaries = getListBestiaries();

    // Init
    this.form.controls.codeBestiary.setValue(this._storeService.creature.codeBestiary || this.allBestiaries[0].code);

    this.outCheck.emit(!this.form.invalid);
    this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this._storeService.modifyCreature();
      this.outCheck.emit(!this.form.invalid);
      this._save();
    });
  }

  public openRacePopup() {
    this.dialog.open(RaceModal, {
      data: this.currentBestiary ? this.currentBestiary : null,
    });
  }

  public openRaceURL() {
    const url = this._router.serializeUrl(this._router.createUrlTree(['univers/races']));
    window.open(url, '_blank');
  }

  private _save() {
    const newCreature = new ArlenorCreature();
    newCreature.codeBestiary = this.form.controls.codeBestiary.value;
    this._storeService.changeCharacterBestiary(newCreature);
  }
}
