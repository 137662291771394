import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SkillModal } from '@src/components/modals/skill/skill.modal';
import { ArlenorLevel } from '@src/models/arlenor/ArlenorLevel';
import { ArlenorSkill, SkillTypesEnum } from '@src/models/arlenor/ArlenorSkill';
import { ModelEnum } from '@src/models/ModelEnum';

@Component({
  selector: 'skills-selection',
  templateUrl: './skills-selection.component.html',
  styleUrls: ['./skills-selection.component.scss'],
})
export class SkillsSelectionComponent implements OnInit, OnChanges {
  @Input() public level: ArlenorLevel;
  @Input() public idsSkills: number[];
  @Input() public skills: ArlenorSkill[];
  @Output() public outAdd = new EventEmitter<ArlenorSkill>();
  @Output() public outRemove = new EventEmitter<ArlenorSkill>();

  public types: ModelEnum[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public typesSkills: any;

  constructor(public dialog: MatDialog) {}

  public ngOnInit(): void {
    this.updateTypes();
  }

  public ngOnChanges(): void {
    this.updateTypes();
  }

  public updateTypes() {
    if (!this.skills) return;
    this.types = [SkillTypesEnum.Weapon, SkillTypesEnum.Other];
    this.typesSkills = {
      [SkillTypesEnum.Weapon.Code]: this._getSkillsByType(SkillTypesEnum.Weapon.Code),
      [SkillTypesEnum.Other.Code]: this._getSkillsByType(SkillTypesEnum.Other.Code),
    };
  }

  public checkSkill(skill: ArlenorSkill) {
    return this.idsSkills.includes(skill.id);
  }

  public changeSkill(skill: ArlenorSkill) {
    if (!this.checkSkill(skill)) this.outAdd.emit(skill);
    else this.outRemove.emit(skill);
  }

  public checkDisabled(skill: ArlenorSkill) {
    if (this.checkSkill(skill)) return false;
    return this.idsSkills.length >= this.level.maxSkills;
  }

  public seeMore(skill: ArlenorSkill) {
    this.dialog.open(SkillModal, {
      data: skill,
    });
  }

  private _getSkillsByType(codeType: string) {
    const skills = this.skills.filter(skill => skill.codeType === codeType);
    skills.sort((a, b) => a.name.localeCompare(b.name));
    return skills;
  }
}
