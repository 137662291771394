<h2 class="u-textCenter u-marginBottom2">Choix dans la liste</h2>

<div class="card u-width100">
  <form class="u-flexRow u-flexColumn-on-tablet" [formGroup]="form">
    <mat-form-field>
      <mat-label>Race</mat-label>
      <mat-select formControlName="codeRace">
        <mat-select-trigger>{{ selectedRaceName }}</mat-select-trigger>
        <mat-option [value]="''">Toutes les races</mat-option>
        <mat-option *ngFor="let race of allRaces" [value]="race.code">
          <span [class]="'u-marginRight05 ' + getDifficultyColor(race)">{{ getDifficultyLibelle(race) }}</span>
          {{ race.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Classe</mat-label>
      <mat-select formControlName="codeSpeciality">
        <mat-select-trigger>{{ selectedSpeName }}</mat-select-trigger>
        <mat-option [value]="''">Toutes les classes</mat-option>
        <mat-option *ngFor="let spe of allSpecialities" [value]="spe.code">
          <span [class]="'status-' + spe.color">
            <span class="u-marginRight05" [class]="spe.role.icon"></span>
            <span>{{ spe.role.name }}</span>
          </span>
          {{ spe.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <div *ngIf="!filteredCharacters.length" class="character-noselection">
    Aucun personnage sélectionnable avec les filtres actuels.
  </div>
  <div *ngIf="filteredCharacters.length" class="character-selection">
    <div
      *ngFor="let character of filteredCharacters"
      class="character-selection-item"
      [class.is-selected]="selectedCharacter?.guid === character.guid"
      (click)="selectCharacter(character.guid)"
    >
      <img class="character-selection-img" [src]="character.profil" [alt]="character.name" />
      <div class="character-selection-text">
        <div>{{ character.name }}</div>
        <div class="character-selection-subtitle">
          {{ getDetails(character) }}
        </div>
        <div *ngIf="character.isVersionOK" class="character-selection-subtitle">
          <i
            [class]="character.speciality01.role.icon + ' u-text' + (character.speciality01.color | capitalize)"
            [title]="character.speciality01.role.name"
          ></i>
          <i
            *ngIf="character.speciality02"
            [class]="character.speciality02.role.icon + ' u-text' + (character.speciality02.color | capitalize)"
            [title]="character.speciality02.role.name"
          ></i>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="u-flexRow u-flexWrap u-justifyCenter u-marginTop2">
  <button type="button" mat-stroked-button (click)="reset()">
    <i class="icon-arrow-left"></i>
    Retour
  </button>
  <button
    type="button"
    mat-stroked-button
    color="primary"
    [disabled]="!form.valid || needsToBeDeleted"
    (click)="create()"
  >
    Modifier le personnage
  </button>
</div>

@if (selectedCharacter) {
  <div class="u-flexRow u-alignStart u-flexColumn-on-tablet u-alignCenter-on-tablet u-marginTop2">
    @if (!needsToBeDeleted) {
      <div class="card">
        <p class="u-textCenter">
          Vous pouvez télécharger la fiche personnage de <b>{{ selectedCharacter.name }} au niveau 1</b>.
          <br />
          Si vous souhaitez un autre niveau, il vous faudra cliquer sur <b>Modifier le personnage</b> et réaliser son
          passage de niveau.
        </p>
        <div class="u-flexRow u-flexWrap u-justifyCenter u-marginTop1">
          <button type="button" mat-stroked-button [disabled]="needsToBeDeleted" (click)="download(false)">
            Télécharger sa fiche<br />(en noir et blanc)
          </button>
          <button
            type="button"
            mat-stroked-button
            color="primary"
            [disabled]="needsToBeDeleted"
            (click)="download(true)"
          >
            Télécharger sa fiche<br />(en couleurs)
          </button>
        </div>
      </div>
    }

    @if (!selectedCharacter.isBDD) {
      <div class="card" [class.is-warning]="needsToBeDeleted">
        <p class="u-textCenter">
          Votre personnage <b>{{ selectedCharacter.name }}</b> peut être supprimé de la liste, car il est sauvegardé
          localement.
          <span *ngIf="needsToBeDeleted"><br />Sa version est ancienne, devenue incompatible.</span>
        </p>
        <div class="u-flexRow u-justifyCenter u-marginTop1">
          <button type="button" mat-stroked-button color="warn" (click)="openDeletePopup()">
            Supprimer le personnage
          </button>
        </div>
      </div>
    }
  </div>
}
