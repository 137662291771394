import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { RaceModal } from '@src/components/modals/race/race.modal';
import { ArlenorCharacter } from '@src/models/arlenor/ArlenorCharacter';
import { ArlenorRace } from '@src/models/arlenor/ArlenorRace';
import { ArlenorSkill } from '@src/models/arlenor/ArlenorSkill';
import { getListRaces } from '@src/models/data/ListRaces';
import { DifficultyEnum } from '@src/models/ModelEnum';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'character-race',
  templateUrl: './character-race.component.html',
  styles: [':host {	display: contents; }'],
})
export class CharacterRaceComponent implements OnInit {
  @Output() public outCheck = new EventEmitter<boolean>();

  public form: FormGroup;
  public allRaces: ArlenorRace[];

  private _destroyRef = inject(DestroyRef);

  public get allSkills(): ArlenorSkill[] {
    return this._storeService.$allSkills.value || [];
  }

  public get currentRace(): ArlenorRace | null {
    const race = this.allRaces.find(race => race.code === this.form.controls.codeRace.value);
    return race ? race : null;
  }

  public getDifficultyColor(race: ArlenorRace) {
    if (race.difficulty === DifficultyEnum.Simple.Code) return 'status-ocean';
    if (race.difficulty === DifficultyEnum.Standard.Code) return 'status-green';
    if (race.difficulty === DifficultyEnum.Complexe.Code) return 'status-yellow';
    else return '';
  }

  public getDifficultyLibelle(race: ArlenorRace) {
    if (race.difficulty === DifficultyEnum.Simple.Code) return DifficultyEnum.Simple.Libelle;
    if (race.difficulty === DifficultyEnum.Standard.Code) return DifficultyEnum.Standard.Libelle;
    if (race.difficulty === DifficultyEnum.Complexe.Code) return DifficultyEnum.Complexe.Libelle;
    else return '';
  }

  public get selectedRaceName() {
    const codeRace = this.form.controls.codeRace.value;
    if (codeRace) return this.allRaces.find(race => race.code === codeRace).name;
    else return '-';
  }

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      codeRace: new FormControl(null, Validators.required),
    });
  }

  public ngOnInit() {
    this.allRaces = getListRaces().filter(race => race.difficulty !== DifficultyEnum.Impossible.Code);

    // Init
    this.form.controls.codeRace.setValue(this._storeService.character.codeRace || this.allRaces[0].code);

    this.outCheck.emit(!this.form.invalid);
    this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this._storeService.modifyCharacter();
      this.outCheck.emit(!this.form.invalid);
      this._save();
    });
  }

  public getSkills(codeRace: string) {
    return this.allSkills.filter(skill => skill.race && skill.race.code === codeRace);
  }

  public openRacePopup() {
    this.dialog.open(RaceModal, {
      data: this.currentRace ? this.currentRace : null,
    });
  }

  public openRaceURL() {
    const url = this._router.serializeUrl(this._router.createUrlTree(['univers/races']));
    window.open(url, '_blank');
  }

  private _save() {
    const newCharacter = new ArlenorCharacter();
    newCharacter.codeRace = this.form.controls.codeRace.value;
    this._storeService.changeCharacterRace(newCharacter);
  }
}
