import { inject, NgModule } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivateFn, RouterModule, Routes } from '@angular/router';

import { CreatureView } from '@src/views/roleplay/creature/creature.view';

import { Observable } from 'rxjs';

import { ConfirmationModal } from './components/modals/confirmation/confirmation.modal';
import { PageTitles } from './models/PagesTitles';
import { StoreService } from './services/store.service';
import { AboutView } from './views/about/about.view';
import { AcademyView } from './views/celestia/academy/academy.view';
import { CelestiaView } from './views/celestia/celestia.view';
import { ChronologyView } from './views/celestia/chronology/chronology.view';
import { HomeView } from './views/home/home.view';
import { KitUIView } from './views/kit-ui/kit-ui.view';
import { LegacyView } from './views/legacy/legacy.view';
import { AvatarView } from './views/roleplay/avatar/avatar.view';
import { CharacterView } from './views/roleplay/character/character.view';
import { CrystalsView } from './views/roleplay/crystals/crystals.view';
import { RoleplayView } from './views/roleplay/roleplay.view';
import { SystemView } from './views/roleplay/system/system.view';
import { CalendarView } from './views/universe/calendar/calendar.view';
import { EncyclopediaView } from './views/universe/encyclopedia/encyclopedia.view';
import { MagicView } from './views/universe/magic/magic.view';
import { RacesView } from './views/universe/races/races.view';
import { ReligionView } from './views/universe/religion/religion.view';
import { UniverseView } from './views/universe/universe.view';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CheckNavGuard: CanDeactivateFn<any> = () => {
  const dialog = inject(MatDialog);
  const store = inject(StoreService);

  return new Observable<boolean>(observer => {
    if (store.hasModification) {
      const dialogRef = dialog.open(ConfirmationModal, {
        data: {
          title: `Changement de page`,
          content: `Vous allez quitter la page : les modifications ne seront pas enregistrées.`,
        },
      });

      dialogRef.afterClosed().subscribe((value: boolean) => {
        observer.next(value);
        observer.complete();
      });
    } else {
      observer.next(true);
      observer.complete();
    }
  });
};

const routes: Routes = [
  { path: '', component: HomeView, title: PageTitles.home },
  { path: 'kit-ui', component: KitUIView, title: PageTitles.kitui },

  { path: 'univers', component: UniverseView, title: PageTitles.worldmap },
  { path: 'univers/calendrier', component: CalendarView, title: PageTitles.calendar },
  { path: 'univers/encyclopedie', component: EncyclopediaView, title: PageTitles.encyclopedia },
  { path: 'univers/religion', component: ReligionView, title: PageTitles.religion },
  { path: 'univers/magie', component: MagicView, title: PageTitles.magic },
  { path: 'univers/races', component: RacesView, title: PageTitles.races },

  { path: 'jeu-de-roles', component: RoleplayView, title: PageTitles.roleplay },
  { path: 'jeu-de-roles/avatar', component: AvatarView, title: PageTitles.avatar },
  { path: 'jeu-de-roles/systeme', component: SystemView, title: PageTitles.system },
  { path: 'jeu-de-roles/cristaux', component: CrystalsView, title: PageTitles.crystals },
  { path: 'jeu-de-roles/personnage', component: CharacterView, title: PageTitles.character, canDeactivate: [CheckNavGuard] },
  { path: 'jeu-de-roles/creature', component: CreatureView, title: PageTitles.creature, canDeactivate: [CheckNavGuard] },

  { path: 'celestia', component: CelestiaView, title: PageTitles.celestia },
  { path: 'celestia/academie', component: AcademyView, title: PageTitles.academy },
  { path: 'celestia/chronologie', component: ChronologyView, title: PageTitles.chronology },

  { path: 'mentions-legales', component: LegacyView, title: PageTitles.legacy },
  { path: 'a-propos', component: AboutView, title: PageTitles.about },
  { path: '*', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
