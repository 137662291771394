import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

import { DifficultyEnum, ModelEnum } from '@src/models/ModelEnum';
import { convertMarkdownToHTML } from '@src/utils/conversions';

@Component({
  selector: 'system-results-view',
  templateUrl: './system-results.view.html',
  styleUrls: [],
})
export class SystemResultsView implements OnInit {
  public systemResultsText01 = systemResultsText01;
  public systemResultsText02 = systemResultsText02;

  public displayedColumns: string[] = ['name', 'score0', 'score1', 'score2', 'score3', 'score4', 'score5'];

  public data: ResultElement[] = [
    {
      name: 'Simple',
      description: '(1 dé gagnant)',
      scores: ['Score max à 1', 'Score max à 2', 'Score max à 3', 'Score max à 4', 'Score max à 5', 'Score max à 6'],
    },
    {
      name: 'Standard',
      description: '(2 dés gagnants)',
      scores: ['Max 1-2', 'Max 3-4', 'Max 5-6', 'Max 4', 'Max 5', 'Max 6'],
    },
    {
      name: 'Complexe',
      description: '(3 dés gagnants)',
      scores: ['Max 1-2', 'Max 3-4', 'Max 5-6', 'Max 4', 'Max 5', 'Max 6'],
    },
    {
      name: 'Épique',
      description: '(4 dés gagnants)',
      scores: ['Max 1-2', 'Max 3-4', 'Max 5-6', 'Max 4', 'Max 5', 'Max 6'],
    },
    {
      name: 'Légendaire',
      description: '(5 dés gagnants)',
      scores: ['Max 1-2', 'Max 3-4', 'Max 5-6', 'Max 4', 'Max 5', 'Max 6'],
    },
  ];
  public dataSource = new MatTableDataSource<ResultElement>(this.data);

  public form: FormGroup;
  public allDifficulties: ModelEnum[];
  public dices: number[];
  public winDices: number;
  public strongDice: number;
  public needWinDices: number;
  public isSuccess: boolean;
  public showResults: boolean;
  public libResult: string;
  public hasBonusCrit: boolean;
  public hasBonusExcend: boolean;
  public hasMalusCosts: boolean;
  public hasMalusConseq: boolean;
  public hasMalusCrit: boolean;
  public nbBonusExcend: number;

  public get selectedDifficulty() {
    const codeDifficulty = this.form.controls.codeDifficulty.value;
    if (codeDifficulty) return this.allDifficulties.find(diff => diff.Code === codeDifficulty);
    else return null;
  }

  constructor() {
    this.form = new FormGroup({
      codeDifficulty: new FormControl(null, Validators.required),
    });
  }

  public ngOnInit() {
    this.allDifficulties = [DifficultyEnum.Simple, DifficultyEnum.Standard, DifficultyEnum.Complexe, DifficultyEnum.Epique, DifficultyEnum.Legendaire];
    this.resetDices();
  }

  public getColor(codeDifficulty: string) {
    if (codeDifficulty === DifficultyEnum.Simple.Code) return 'status-ocean';
    else if (codeDifficulty === DifficultyEnum.Standard.Code) return 'status-green';
    else if (codeDifficulty === DifficultyEnum.Complexe.Code) return 'status-yellow';
    else if (codeDifficulty === DifficultyEnum.Epique.Code) return 'status-red';
    else if (codeDifficulty === DifficultyEnum.Legendaire.Code) return 'status-violet';
    else return '';
  }

  public resetDices() {
    this.showResults = false;
    this.dices = [];
    this.form.controls.codeDifficulty.setValue(DifficultyEnum.Standard.Code);
  }

  public addDice(dice: number) {
    this.dices.push(dice);
  }

  public calculate() {
    this.showResults = true;
    this.winDices = 0;
    this.strongDice = 0;
    this.needWinDices = 0;
    this.libResult = '';
    this.hasBonusCrit = false;
    this.hasBonusExcend = false;
    this.hasMalusCosts = false;
    this.hasMalusConseq = false;
    this.hasMalusCrit = false;
    this.nbBonusExcend = 0;

    this.dices.forEach(dice => {
      if (dice >= 4) this.winDices++;
      if (dice > this.strongDice) this.strongDice = dice;
    });

    const codeDifficulty = this.form.controls.codeDifficulty.value;
    if (codeDifficulty === DifficultyEnum.Simple.Code) {
      this.isSuccess = this.strongDice >= 4;
      this.needWinDices = 1;
      if (this.strongDice === 6) {
        this.libResult = 'Réussite critique';
        this.hasBonusCrit = true;
        this.hasBonusExcend = true;
      } else if (this.strongDice === 5) {
        this.libResult = 'Réussite simple';
        this.hasBonusExcend = true;
      } else if (this.strongDice === 4) {
        this.libResult = 'Réussite à coût';
        this.hasBonusExcend = true;
        this.hasMalusCosts = true;
      } else if (this.strongDice === 3) {
        this.libResult = 'Échec simple';
      } else if (this.strongDice === 2) {
        this.libResult = 'Échec à conséquence';
        this.hasMalusConseq = true;
      } else {
        this.libResult = 'Échec critique';
        this.hasMalusCrit = true;
      }
    } else {
      if (codeDifficulty === DifficultyEnum.Standard.Code) this.needWinDices = 2;
      if (codeDifficulty === DifficultyEnum.Complexe.Code) this.needWinDices = 3;
      if (codeDifficulty === DifficultyEnum.Epique.Code) this.needWinDices = 4;
      if (codeDifficulty === DifficultyEnum.Legendaire.Code) this.needWinDices = 5;
      if (this.winDices >= this.needWinDices) {
        this.isSuccess = true;
        if (this.strongDice === 6) {
          this.libResult = 'Réussite critique';
          this.hasBonusCrit = true;
          this.hasBonusExcend = true;
        } else if (this.strongDice === 5) {
          this.libResult = 'Réussite simple';
          this.hasBonusExcend = true;
        } else {
          this.libResult = 'Réussite à coût';
          this.hasBonusExcend = true;
          this.hasMalusCosts = true;
        }
      } else {
        this.isSuccess = false;
        if (this.strongDice === 6 || this.strongDice === 5) {
          this.libResult = 'Échec simple';
        } else if (this.strongDice === 4 || this.strongDice === 3) {
          this.libResult = 'Échec à conséquence';
          this.hasMalusConseq = true;
        } else {
          this.libResult = 'Échec critique';
          this.hasMalusCrit = true;
        }
      }
    }

    if (this.hasBonusExcend) this.nbBonusExcend = this.winDices - this.needWinDices;
  }
}

export interface ResultElement {
  name: string;
  description: string;
  scores: string[];
}

export const systemResultsText01 = convertMarkdownToHTML(`
  Pour les **tests de difficulté**, on utilisera cette grille de réussites et d'échec comme référence :
  ces tests concernent généralement le personnage lui-même, ou des actions sur des cibles alliées
  (qui n'opposent pas de résistance).

  Pour les **tests d'opposition**, on utilisera la même grille :
  le MJ jugera simplement de la difficulté de l'opposant.
  *Exemple : Pour un type d'ennemi, l'esquiver sera une difficulté Complexe, mais l'attaquer sera une difficulté Simple.
  Autre exemple : Forcer cette porte sera une difficulté Standard.*`);

export const systemResultsText02 = convertMarkdownToHTML(`
  **Réussite critique** : L'action est réussie de manière exceptionnelle,
  obtenant un résultat au-delà des attentes.
  **Réussite simple** : L'action est réussie sans complication ni coût supplémentaire.
  **Réussite à coût** : L'action est réussie, mais avec des complications mineures
  ou des coûts supplémentaires.
  **Échec simple** : L'action échoue, mais sans conséquence.
  **Échec à conséquence** : L'action échoue, mais avec des conséquences mineures.
  **Échec critique** : L'action est un échec total, avec des conséquences graves.`);
