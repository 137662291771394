import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { CaractModal } from '@src/components/modals/caract/caract.modal';
import { ArlenorCreature } from '@src/models/arlenor/ArlenorCreature';
import { CaractDescriptionEnum, CaractNomEnum } from '@src/models/ModelEnum';
import { StoreService } from '@src/services/store.service';

interface CaractsElement {
  libelle: string;
  code: string;
  key: string;
  isNullable: boolean;
}

@Component({
  selector: 'creature-caracts',
  templateUrl: './creature-caracts.component.html',
  styles: [':host {	display: contents; }'],
})
export class CreatureCaractsComponent implements OnInit {
  @Output() public outCheck = new EventEmitter<boolean>();

  public data: CaractsElement[] = [
    {
      libelle: CaractNomEnum.Force.Libelle,
      code: CaractNomEnum.Force.Code,
      key: CaractNomEnum.Force.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Habilete.Libelle,
      code: CaractNomEnum.Habilete.Code,
      key: CaractNomEnum.Habilete.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Intellect.Libelle,
      code: CaractNomEnum.Intellect.Code,
      key: CaractNomEnum.Intellect.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Tenacite.Libelle,
      code: CaractNomEnum.Tenacite.Code,
      key: CaractNomEnum.Tenacite.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Charisme.Libelle,
      code: CaractNomEnum.Charisme.Code,
      key: CaractNomEnum.Charisme.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Magie.Libelle,
      code: CaractNomEnum.Magie.Code,
      key: CaractNomEnum.Magie.Code.toLowerCase(),
      isNullable: true,
    },
  ];
  public dataSource = new MatTableDataSource<CaractsElement>(this.data);

  public caractDescriptionEnum = CaractDescriptionEnum;
  public codeBestiary: string;
  public form: FormGroup;

  private _destroyRef = inject(DestroyRef);

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      for: new FormControl(1),
      hab: new FormControl(1),
      int: new FormControl(1),
      cha: new FormControl(1),
      ten: new FormControl(1),
      mag: new FormControl(1),
    });
  }

  public ngOnInit() {
    this.codeBestiary = this._storeService.creature.codeBestiary;

    // Init
    this.form.controls.for.setValue(this._storeService.creature.caractFor);
    this.form.controls.hab.setValue(this._storeService.creature.caractHab);
    this.form.controls.int.setValue(this._storeService.creature.caractInt);
    this.form.controls.cha.setValue(this._storeService.creature.caractCha);
    this.form.controls.ten.setValue(this._storeService.creature.caractTen);
    this.form.controls.mag.setValue(this._storeService.creature.caractMag);

    this.outCheck.emit(!this.form.invalid);
    this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this._storeService.modifyCreature();
      this.outCheck.emit(!this.form.invalid);
      this._save();
    });
  }

  public getInitiative() {
    return parseInt(this.form.controls.hab.value) + parseInt(this.form.controls.int.value);
  }

  public getPointsDeVie() {
    let points = 10;
    if (parseInt(this.form.controls.ten.value) === 1) points -= 5;
    else if (parseInt(this.form.controls.ten.value) > 3) points += 5;
    return points;
  }

  public openCaractPopup(caract: string) {
    this.dialog.open(CaractModal, {
      data: caract,
    });
  }

  private _save() {
    const newCreature = new ArlenorCreature();
    newCreature.caractFor = parseInt(this.form.controls.for.value);
    newCreature.caractHab = parseInt(this.form.controls.hab.value);
    newCreature.caractInt = parseInt(this.form.controls.int.value);
    newCreature.caractTen = parseInt(this.form.controls.ten.value);
    newCreature.caractCha = parseInt(this.form.controls.cha.value);
    newCreature.caractMag = parseInt(this.form.controls.mag.value);
    this._storeService.changeCreatureCaracts(newCreature);
  }
}
