import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { PowerModal } from '@src/components/modals/power/power.modal';
import { ArlenorPower, PowerRanksEnum } from '@src/models/arlenor/ArlenorPower';
import { ModelEnum } from '@src/models/ModelEnum';

@Component({
  selector: 'creature-powers-selection',
  templateUrl: './creature-powers-selection.component.html',
  styleUrls: ['./creature-powers-selection.component.scss'],
})
export class CreaturePowersSelectionComponent implements OnInit, OnChanges {
  @Input() public idsPowers: number[];
  @Input() public powers: ArlenorPower[];
  @Input() public disabled: boolean;
  @Output() public outAdd = new EventEmitter<ArlenorPower>();
  @Output() public outRemove = new EventEmitter<ArlenorPower>();

  public ranks: ModelEnum[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public rankPowers: any;

  constructor(public dialog: MatDialog) {}

  public ngOnInit(): void {
    this.updateRanks();
  }

  public ngOnChanges(): void {
    this.updateRanks();
  }

  public updateRanks() {
    if (!this.powers) return;
    this.ranks = this.powers.map(power => power.rank).filter((value, index, categoryArray) => categoryArray.indexOf(value) === index);
    this.ranks.sort((a, b) => b.Code.localeCompare(a.Code));
    this.rankPowers = {
      [PowerRanksEnum.Commun.Code]: this._getPowersByRank(PowerRanksEnum.Commun.Code),
      [PowerRanksEnum.Rare.Code]: this._getPowersByRank(PowerRanksEnum.Rare.Code),
      [PowerRanksEnum.TresRare.Code]: this._getPowersByRank(PowerRanksEnum.TresRare.Code),
    };
  }

  public checkPower(power: ArlenorPower) {
    return this.idsPowers.includes(power.id);
  }

  public changePower(power: ArlenorPower) {
    if (!this.checkPower(power)) this.outAdd.emit(power);
    else this.outRemove.emit(power);
  }

  public seeMore(power: ArlenorPower) {
    this.dialog.open(PowerModal, {
      data: power,
    });
  }

  private _getPowersByRank(rank: string) {
    return this.powers.filter(power => power.codeRank === rank);
  }
}
