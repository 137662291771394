<h2 class="u-textCenter u-marginBottom2">Formulaire</h2>

<div class="sides">
  <div class="sides-left">
    <div class="sides-left-bloc">
      <creature-resume></creature-resume>
      <button type="button" mat-stroked-button [color]="isModified ? 'warn' : ''" (click)="reset()">
        <i class="icon-arrow-left"></i>
        <ng-container *ngIf="!isModified">Retour à l'introduction</ng-container>
        <ng-container *ngIf="isModified">Annuler les modifications</ng-container>
      </button>
    </div>
  </div>
  <div class="sides-right">
    <div class="u-flexColumn">
      <creature-bestiary (outCheck)="check('bestiary', $event)"></creature-bestiary>
      <creature-caracts (outCheck)="check('caracts', $event)"></creature-caracts>
      <creature-powers (outCheck)="check('powers', $event)"></creature-powers>
      <creature-identity (outCheck)="check('identity', $event)"></creature-identity>

      <div class="u-flexRow u-justifyCenter u-marginTop1">
        <button type="button" mat-stroked-button color="primary" [disabled]="form.invalid" (click)="finish()">
          Confirmer la création
        </button>
      </div>
    </div>
  </div>
</div>
