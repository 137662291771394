<div class="bg-roleplay">
  <div class="layout-view">
    <h1 class="u-marginBottom05">{{ title }}</h1>

    @if (!isSelection && !isCreation && !isFinal) {
      <creature-intro-view (outSelect)="select()" (outCreate)="create()"></creature-intro-view>
    } @else if (isSelection) {
      <creature-selection-view (outReset)="reset()" (outCreate)="create()"></creature-selection-view>
    } @else if (isCreation) {
      <creature-creation-view (outReset)="reset()" (outFinish)="finish()"></creature-creation-view>
    } @else {
      <creature-final-view (outBack)="create()" (outReset)="reset()"></creature-final-view>
    }
  </div>
</div>
