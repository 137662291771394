<creature-template [number]="1" label="Choix du type de créature" [isInvalid]="!form.valid">
  <form [formGroup]="form" class="u-flexColumn">
    <mat-form-field>
      <mat-label>Type de créature</mat-label>
      <mat-select formControlName="codeBestiary">
        <mat-select-trigger>{{ selectedBestiaryName }}</mat-select-trigger>
        <mat-option *ngFor="let bestiary of allBestiaries" [value]="bestiary.code">
          {{ bestiary.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    @if (currentBestiary) {
      <div class="card">
        {{ currentBestiary.description }}
        <br /><i>{{ currentBestiary.examples }}</i>
      </div>
    }
  </form>
</creature-template>
