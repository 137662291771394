<div class="encyclopedia-view bg-universe">
  <div class="layout-view">
    <h1>{{ title }}</h1>

    <div class="sides u-marginTop2">
      <div class="sides-left">
        <tree-menu
          [hasSearch]="true"
          [currentCode]="currentCode"
          [menu]="encycloMenu"
          (outChangeCode)="moveToSection($event)"
        >
        </tree-menu>
      </div>
      <div class="sides-right">
        <head-section
          [previousSection]="previousSection"
          [currentSection]="currentSection"
          [nextSection]="nextSection"
          (outChange)="moveToSection($event)"
        >
        </head-section>

        <encyclo-factions-view *ngIf="currentCode === 'EncycloFactionsOffView'" [isOff]="true"></encyclo-factions-view>
        <encyclo-factions-view *ngIf="currentCode === 'EncycloFactionsSecView'" [isOff]="false"></encyclo-factions-view>

        <encyclo-faradel-land-view *ngIf="currentCode === 'EncycloFaradelLandView'"></encyclo-faradel-land-view>
        <encyclo-jirakan-land-view *ngIf="currentCode === 'EncycloJirakanLandView'"></encyclo-jirakan-land-view>
        <encyclo-dakaros-land-view *ngIf="currentCode === 'EncycloDakarosLandView'"></encyclo-dakaros-land-view>
        <encyclo-ether-land-view *ngIf="currentCode === 'EncycloEtherLandView'"></encyclo-ether-land-view>
        <encyclo-atlantica-land-view *ngIf="currentCode === 'EncycloAtlanticaLandView'"></encyclo-atlantica-land-view>
        <encyclo-somni-land-view *ngIf="currentCode === 'EncycloSomniLandView'"></encyclo-somni-land-view>
        <encyclo-no-land-view *ngIf="currentCode === 'EncycloNoLandView'"></encyclo-no-land-view>

        <encyclo-bestiary-view
          *ngIf="currentCode === 'EncycloBestiaryAnimalsView'"
          [indexBestiary]="0"
        ></encyclo-bestiary-view>
        <encyclo-bestiary-view
          *ngIf="currentCode === 'EncycloBestiaryMagicsView'"
          [indexBestiary]="1"
        ></encyclo-bestiary-view>
        <encyclo-bestiary-view
          *ngIf="currentCode === 'EncycloBestiaryDivinitiesView'"
          [indexBestiary]="2"
        ></encyclo-bestiary-view>
        <encyclo-bestiary-view
          *ngIf="currentCode === 'EncycloBestiaryMonstersView'"
          [indexBestiary]="3"
        ></encyclo-bestiary-view>

        <encyclo-divine-view *ngIf="currentCode === 'EncycloDivineView'"></encyclo-divine-view>
        <encyclo-societe-eveil-view *ngIf="currentCode === 'EncycloSocieteEveilView'"></encyclo-societe-eveil-view>
        <encyclo-coalition-view *ngIf="currentCode === 'EncycloCoalitionView'"></encyclo-coalition-view>

        <footer-section
          [previousSection]="previousSection"
          [nextSection]="nextSection"
          (outChange)="moveToSection($event)"
        >
        </footer-section>
      </div>
    </div>
  </div>
</div>
