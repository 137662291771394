import { Component, OnInit } from '@angular/core';

import { PageTitles } from '@src/models/PagesTitles';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'creature-view',
  templateUrl: './creature.view.html',
})
export class CreatureView implements OnInit {
  public title = PageTitles.creature;
  public isSelection: boolean;
  public isCreation: boolean;
  public isFinal: boolean;

  constructor(private _storeService: StoreService) {}

  public ngOnInit() {
    this._storeService.loadAllArchetypes();
    this._storeService.loadAllPowers();
    this._storeService.loadAllSkills();
    this.reset();
  }

  public create(): void {
    this.isSelection = false;
    this.isCreation = true;
    this.isFinal = false;
    window.scroll(0, 0);
  }

  public select(): void {
    this.isSelection = true;
    this.isCreation = false;
    this.isFinal = false;
    window.scroll(0, 0);
  }

  public finish(): void {
    this.isSelection = false;
    this.isCreation = false;
    this.isFinal = true;
    window.scroll(0, 0);
  }

  public reset(): void {
    this.isSelection = false;
    this.isCreation = false;
    this.isFinal = false;
    this._storeService.resetCreature();
    window.scroll(0, 0);
  }
}
