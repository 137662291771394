import { Component, Input } from '@angular/core';

import { ArlenorCreature } from '@src/models/arlenor/ArlenorCreature';
import { ArlenorPower } from '@src/models/arlenor/ArlenorPower';
import { ArlenorSkill } from '@src/models/arlenor/ArlenorSkill';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'creature-resume',
  templateUrl: './creature-resume.component.html',
  styleUrls: ['./creature-resume.component.scss'],
})
export class CreatureResumeComponent {
  @Input() public hasLevel: boolean;

  public get creature(): ArlenorCreature {
    return this._storeService.creature;
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  public get allSkills(): ArlenorSkill[] {
    return this._storeService.$allSkills.value || [];
  }

  public get powers(): ArlenorPower[] {
    return this.allPowers.filter(power => this.creature.idsPowers.includes(power.id));
  }

  constructor(private _storeService: StoreService) {}
}
