import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs';
import { PageTitles } from 'src/app/models/PagesTitles';
import { StoreService } from 'src/app/services/store.service';

import { UserFormModal } from '../modals/user-form/user-form.modal';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this._toggleOverflowHidden();
  }

  public pages = PageTitles;
  public showSubmenu = false;

  public onHome: boolean;
  public onUniverseRoute: boolean;
  public onRoleplayRoute: boolean;
  public onCelestiaRoute: boolean;

  public activeOptions: IsActiveMatchOptions = {
    paths: 'exact',
    matrixParams: 'exact',
    queryParams: 'ignored',
    fragment: 'exact',
  };

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  public get isMaster(): boolean {
    return this._storeService.isMaster;
  }

  public get isPlayer(): boolean {
    return this._storeService.isPlayer;
  }

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService,
    private _router: Router
  ) {}

  public ngOnInit(): void {
    this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const currentUrl = this._router.url;
      this.onHome = currentUrl === '/';
      this.onUniverseRoute = currentUrl.includes('/univers');
      this.onRoleplayRoute = currentUrl.includes('/jeu-de-roles');
      this.onCelestiaRoute = currentUrl.includes('/celestia');
    });
  }

  public openUserPopup() {
    this.dialog.open(UserFormModal, {
      data: {},
    });
  }

  public hideMenu() {
    this.showSubmenu = false;
    this._toggleOverflowHidden();
  }

  public toggleMenu() {
    this.showSubmenu = !this.showSubmenu;
    this._toggleOverflowHidden();
  }

  private _toggleOverflowHidden() {
    const overflowHidden = this.showSubmenu && window.innerWidth <= 674;
    if (overflowHidden) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }
}
