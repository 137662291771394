<mat-tab-group animationDuration="0ms" class="u-marginTop1">
  <mat-tab label="Les tableaux et résultats">
    <div class="card u-marginTop1" [innerHtml]="systemResultsText01"></div>

    <div class="mdc-table-container u-marginTop1">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Difficulté</th>
          <td mat-cell *matCellDef="let element" class="u-textBold">
            {{ element.name }}<br />{{ element.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="score0">
          <th mat-header-cell *matHeaderCellDef>Échec critique</th>
          <td mat-cell *matCellDef="let element">{{ element.scores[0] }}</td>
        </ng-container>

        <ng-container matColumnDef="score1">
          <th mat-header-cell *matHeaderCellDef>Échec à conséquence</th>
          <td mat-cell *matCellDef="let element">{{ element.scores[1] }}</td>
        </ng-container>

        <ng-container matColumnDef="score2">
          <th mat-header-cell *matHeaderCellDef>Échec simple</th>
          <td mat-cell *matCellDef="let element">{{ element.scores[2] }}</td>
        </ng-container>

        <ng-container matColumnDef="score3">
          <th mat-header-cell *matHeaderCellDef>Réussite à coût</th>
          <td mat-cell *matCellDef="let element">{{ element.scores[3] }}</td>
        </ng-container>

        <ng-container matColumnDef="score4">
          <th mat-header-cell *matHeaderCellDef>Réussite simple</th>
          <td mat-cell *matCellDef="let element">{{ element.scores[4] }}</td>
        </ng-container>

        <ng-container matColumnDef="score5">
          <th mat-header-cell *matHeaderCellDef>Réussite critique</th>
          <td mat-cell *matCellDef="let element">{{ element.scores[5] }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <div class="card u-marginTop1" [innerHtml]="systemResultsText02"></div>

    <div class="card u-marginTop1">
      <p><b>Réussite critique :</b> +1 PV / +1 Dégât / +1 Tour (choisir un seul).</p>
      <p class="u-marginTop1">
        <b>Succès excédentaires (par rapport au seuil requis) :</b>
      </p>
      <ul>
        <li><b>+1 Succès :</b> +1 PV / +1 Dégât / +1 Tour (choisir un seul).</li>
        <li><b>+2 Succès :</b> +2 PVs / +2 Dégâts / +2 Tours (toujours un seul bonus au choix).</li>
        <li><b>+3 Succès :</b> +3 PVs / +3 Dégâts / +3 Tours (toujours un seul bonus au choix).</li>
        <li><b>+4 Succès ou plus :</b> Capé à +3 max (évite l'overdose de buffs).</li>
      </ul>
      <p class="u-marginTop1">
        <i>Note: La réussite critique et les succès excédentaires n'ajoutent pas de bonus pour les Caractéristiques.</i>
      </p>
      <p class="u-marginTop1">
        <b>Conséquences ou coûts :</b>
        fatigue, légère perte de points de vie, position désavantageuse, arme coincée...
      </p>
      <p class="u-marginTop1">
        <b>Échec critique :</b>
        dommages collatéraux, blessure grave, destruction d'un objet, renforcement de l'ennemi...
      </p>
    </div>
  </mat-tab>

  <mat-tab label="Le simulateur de lancés">
    @if (!showResults) {
      <form class="card u-marginTop1" [formGroup]="form">
        <mat-form-field>
          <mat-label>Choix de la difficulté</mat-label>
          <mat-select formControlName="codeDifficulty">
            <mat-select-trigger>{{ selectedDifficulty?.Libelle }}</mat-select-trigger>
            <mat-option *ngFor="let difficulty of allDifficulties" [value]="difficulty.Code">
              <span [class]="getColor(difficulty.Code)">{{ difficulty.Libelle }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <p class="u-marginTop1">Sélectionner vos dés :</p>

        <div class="u-flexRow u-marginTop1">
          <button type="button" mat-icon-button (click)="addDice(1)">1</button>
          <button type="button" mat-icon-button (click)="addDice(2)">2</button>
          <button type="button" mat-icon-button (click)="addDice(3)">3</button>
          <button type="button" mat-icon-button (click)="addDice(4)">4</button>
          <button type="button" mat-icon-button (click)="addDice(5)">5</button>
          <button type="button" mat-icon-button (click)="addDice(6)">6</button>
        </div>

        @if (dices.length) {
          <div class="u-flexRow u-marginTop1">
            @for (dice of dices; track dice) {
              @if (dice === 1) {
                <i class="icon-dice-one u-textXXL"></i>
              } @else if (dice === 2) {
                <i class="icon-dice-two u-textXXL"></i>
              } @else if (dice === 3) {
                <i class="icon-dice-three u-textXXL"></i>
              } @else if (dice === 4) {
                <i class="icon-dice-four u-textXXL"></i>
              } @else if (dice === 5) {
                <i class="icon-dice-five u-textXXL"></i>
              } @else if (dice === 6) {
                <i class="icon-dice-six u-textXXL"></i>
              }
            }
          </div>
        } @else {
          <p class="u-marginTop1">Pas de dé sélectionné.</p>
        }

        <div class="u-flexRow u-marginTop1">
          <button
            type="button"
            mat-stroked-button
            color="primary"
            [disabled]="form.invalid || !dices.length"
            (click)="calculate()"
          >
            Voir le résultat
          </button>
          @if (dices.length) {
            <button type="button" mat-stroked-button color="warn" (click)="resetDices()">Réinitialiser</button>
          }
        </div>
      </form>
    } @else {
      <div class="card u-flexColumn u-marginTop1">
        <h3 [class.u-textRed]="!isSuccess" [class.u-textGreen]="isSuccess">{{ libResult }}</h3>
        <p>
          Difficulté : <span [class]="getColor(selectedDifficulty.Code)">{{ selectedDifficulty.Libelle }}</span>
        </p>
        <p>
          Dés lancés : <b>{{ dices }}</b>
          <br />
          Dés gagnants : <b>{{ winDices }} sur {{ needWinDices }}</b> nécessaires
          <br />
          Score max : <b>{{ strongDice }}</b>
        </p>
        @if (hasBonusCrit) {
          <p>Réussite critique : <b>+1 PV / +1 Dégât / +1 Tour</b></p>
        }
        @if (hasBonusExcend && nbBonusExcend) {
          <p>
            @if (nbBonusExcend === 1) {
              Succès excédentaire :
              <b>+1 PV / +1 Dégât / +1 Tour</b>
            } @else {
              Succès excédentaires :
              <b>+{{ nbBonusExcend }} PVs / +{{ nbBonusExcend }} Dégâts / +{{ nbBonusExcend }} Tours</b>
            }
          </p>
        }
        @if (hasMalusCosts || hasMalusConseq) {
          <p>
            @if (hasMalusCosts) {
              Coûts :
            }
            @if (hasMalusConseq) {
              Conséquences :
            }
            <b>fatigue, légère perte de points de vie, position désavantageuse, arme coincée...</b>
          </p>
        }
        @if (hasMalusCrit) {
          <p>
            Échec critique :
            <b>dommages collatéraux, blessure grave, destruction d'un objet, renforcement de l'ennemi...</b>
          </p>
        }
        <div class="u-flexRow u-marginTop1">
          <button type="button" mat-stroked-button (click)="resetDices()">Relancer</button>
        </div>
      </div>
    }
  </mat-tab>
</mat-tab-group>
