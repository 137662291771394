import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationModal } from '@src/components/modals/confirmation/confirmation.modal';
import { ArlenorCharacter } from '@src/models/arlenor/ArlenorCharacter';
import { ArlenorPower } from '@src/models/arlenor/ArlenorPower';
import { ArlenorRace } from '@src/models/arlenor/ArlenorRace';
import { ArlenorSkill } from '@src/models/arlenor/ArlenorSkill';
import { ArlenorSpeciality } from '@src/models/arlenor/ArlenorSpeciality';
import { getListRaces } from '@src/models/data/ListRaces';
import { ArlenorSpecialities } from '@src/models/data/ListSpecialities';
import { DifficultyEnum } from '@src/models/ModelEnum';
import { StoreService } from '@src/services/store.service';
import downloads_characters from '@src/utils/downloads_characters';

@Component({
  selector: 'character-selection-view',
  templateUrl: './character-selection.view.html',
  styleUrls: ['./character-selection.view.scss'],
})
export class CharacterSelectionView implements OnInit {
  @Output() public outReset = new EventEmitter();
  @Output() public outCreate = new EventEmitter();

  public form: FormGroup;
  public allRaces: ArlenorRace[];
  public allSpecialities: ArlenorSpeciality[];

  private _destroyRef = inject(DestroyRef);

  public get selectedCharacter(): ArlenorCharacter | null {
    if (this.form.controls.guid) {
      const selectedCharacter = this.filteredCharacters.find(character => character.guid === this.form.controls.guid.value);
      return selectedCharacter ? selectedCharacter : null;
    } else return null;
  }

  public get needsToBeDeleted(): boolean {
    return this.selectedCharacter && !this.selectedCharacter.isVersionOK;
  }

  public get characters(): ArlenorCharacter[] {
    const allCharacters = this._storeService.$allCharacters.value || [];
    const localCharacters = this._storeService.localCharacters || [];
    return allCharacters.concat(localCharacters);
  }

  public get filteredCharacters(): ArlenorCharacter[] {
    let characters = this.characters.slice();
    if (this.form.controls.codeRace.value) characters = characters.filter(character => character.codeRace === this.form.controls.codeRace.value);
    if (this.form.controls.codeSpeciality.value)
      characters = characters.filter(
        character =>
          character.codeSpeciality01 === this.form.controls.codeSpeciality.value || character.codeSpeciality02 === this.form.controls.codeSpeciality.value
      );
    return characters;
  }

  public get allSkills(): ArlenorSkill[] {
    return this._storeService.$allSkills.value || [];
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  public getDifficultyColor(race: ArlenorRace) {
    if (race.difficulty === DifficultyEnum.Simple.Code) return 'status-ocean';
    if (race.difficulty === DifficultyEnum.Standard.Code) return 'status-green';
    if (race.difficulty === DifficultyEnum.Complexe.Code) return 'status-yellow';
    else return '';
  }

  public getDifficultyLibelle(race: ArlenorRace) {
    if (race.difficulty === DifficultyEnum.Simple.Code) return DifficultyEnum.Simple.Libelle;
    if (race.difficulty === DifficultyEnum.Standard.Code) return DifficultyEnum.Standard.Libelle;
    if (race.difficulty === DifficultyEnum.Complexe.Code) return DifficultyEnum.Complexe.Libelle;
    else return '';
  }

  public get selectedRaceName() {
    const codeRace = this.form.controls.codeRace.value;
    if (codeRace) return this.allRaces.find(race => race.code === codeRace).name;
    else return 'Toutes les races';
  }

  public get selectedSpeName() {
    const codeSpeciality = this.form.controls.codeSpeciality.value;
    if (codeSpeciality) return this.allSpecialities.find(spe => spe.code === codeSpeciality).name;
    else return 'Toutes les classes';
  }

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      guid: new FormControl(null, Validators.required),
      codeRace: new FormControl(''),
      codeSpeciality: new FormControl(''),
    });
  }

  public ngOnInit() {
    this._storeService.loadAllCharacters(true);
    this._storeService.loadLocalCharacters();

    this.allRaces = getListRaces().filter(race => race.difficulty !== DifficultyEnum.Impossible.Code);
    this.allSpecialities = ArlenorSpecialities.getListSpecialities();

    this.form.controls.codeRace.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.form.controls.guid.setValue(null);
    });

    this.form.controls.codeSpeciality.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.form.controls.guid.setValue(null);
    });
  }

  public getDetails(character: ArlenorCharacter) {
    if (character.isVersionOK) {
      let libelle = character.race?.name;
      if (character.speciality01) libelle += ' - ' + character.speciality01.name;
      if (character.speciality02) libelle += '/' + character.speciality02.name;
      if (!character.isBDD) libelle += ' (' + character.date + ' - ' + character.hour + ')';
      return libelle;
    } else {
      return '(ancienne version à supprimer)';
    }
  }

  public create() {
    if (this.form.controls.guid.value) {
      this._storeService.changeCharacter(this.selectedCharacter);
      this._storeService.resetGUIDCharacter();
    } else this._storeService.resetCharacter();
    this.outCreate.emit();
  }

  public reset() {
    this.outReset.emit();
  }

  public selectCharacter(guid: string) {
    this.form.controls.guid.setValue(guid);
  }

  public async download(isColored: boolean) {
    downloads_characters.downloadPDF(isColored, this.selectedCharacter, this.allSkills, this.allPowers);
  }

  public openDeletePopup() {
    const dialogRef = this.dialog.open(ConfirmationModal, {
      data: {
        title: `Suppression du personnage`,
        content: `Souhaitez-vous vraiment supprimer le personnage nommé <b>${this.selectedCharacter.name}</b> ?<br><br>Cette action est irréversible.`,
      },
    });

    dialogRef.afterClosed().subscribe((value: boolean) => {
      if (value) {
        this._storeService.deleteLocalCharacter(this.selectedCharacter.guid);
        this._storeService.resetCharacter();
        this.form.controls.guid.setValue('');
      }
    });
  }
}
