<div class="character-resume card">
  <img class="character-resume-img" [src]="character.profil" [alt]="character.name" />
  <h2 class="u-textEllipsis u-marginTop1 u-marginBottom05">{{ character.name || 'Inconnu' }}</h2>
  <p class="u-marginBottom0">
    Niveau <b>{{ character.numLevel }}</b>
  </p>
  @if (hasLevel) {
    <p *ngIf="character.age || character.gender" class="u-marginBottom025">
      @if (character.age && character.gender) {
        <b>{{ character.age }}</b> ans, <b>{{ character.gender }}</b>
      } @else if (character.age) {
        <b>{{ character.age }}</b> ans
      } @else {
        <b>{{ character.gender }}</b>
      }
    </p>
    <div class="u-flexColumn u-marginTop1">
      <div *ngIf="character.divinity">
        <p class="u-marginBottom025">Divinité</p>
        <p class="u-marginBottom0">
          <b>{{ character.divinity.name }}</b>
        </p>
      </div>
      <div>
        <p class="u-marginBottom025">Race</p>
        <p class="u-marginBottom0">
          <b>{{ character.race?.name || '-' }}</b>
        </p>
      </div>
      <div>
        <p class="u-marginBottom025">Caractéristiques</p>
        <div class="u-flexRow">
          <div class="u-flexColumn u-gap0">
            <p class="u-flexRow u-justifyBetween u-marginBottom0">
              <span>FOR</span>
              <span>
                <b>{{ character.caractFor || '-' }}</b>
              </span>
            </p>
            <p class="u-flexRow u-justifyBetween u-marginBottom0">
              <span>HAB</span>
              <span>
                <b>{{ character.caractHab || '-' }}</b>
              </span>
            </p>
            <p class="u-flexRow u-justifyBetween u-marginBottom0">
              <span>INT</span>
              <span>
                <b>{{ character.caractInt || '-' }}</b>
              </span>
            </p>
          </div>
          <div class="u-flexColumn u-gap0">
            <p class="u-flexRow u-justifyBetween u-marginBottom0">
              <span>TEN</span>
              <span>
                <b>{{ character.caractTen || '-' }}</b>
              </span>
            </p>
            <p class="u-flexRow u-justifyBetween u-marginBottom0">
              <span>CHA</span>
              <span>
                <b>{{ character.caractCha || '-' }}</b>
              </span>
            </p>
            <p class="u-flexRow u-justifyBetween u-marginBottom0">
              <span>MAG</span>
              <span>
                <b>{{ character.caractMag || '-' }}</b>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <p class="u-marginBottom025">Classe principale</p>
        <p class="u-marginBottom0">
          <b>{{ character.speciality01?.name || '-' }}</b>
        </p>
      </div>
      @if (character.numLevel >= 5) {
        <div>
          <p class="u-marginBottom025">Classe secondaire</p>
          <p class="u-marginBottom0">
            <b>{{ character.speciality02?.name || '-' }}</b>
          </p>
        </div>
      }
      <div>
        <p class="u-marginBottom025">Pouvoirs</p>
        <p class="u-marginBottom0">
          <b *ngIf="!powers.length">-</b>
        </p>
        <ul>
          @for (power of powers; track power.id; let isLast = $last) {
            <li>
              <b>{{ power.name }}</b>
            </li>
          }
        </ul>
      </div>
      <div>
        <p class="u-marginBottom025">Compétences</p>
        <p class="u-marginBottom0">
          <b *ngIf="!skills.length">-</b>
        </p>
        <ul>
          @for (skill of skills; track skill.id; let isLast = $last) {
            <li>
              <b>{{ skill.name }}</b>
            </li>
          }
        </ul>
      </div>
    </div>
  }
</div>
