import { Component, Input, OnInit } from '@angular/core';

import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'creature-template',
  templateUrl: './creature-template.component.html',
  styles: [':host {	display: contents; }'],
})
export class CreatureTemplateComponent implements OnInit {
  @Input() public number: number;
  @Input() public label: string;
  @Input() public isInvalid: boolean;

  public isOpen: boolean;

  public get isModified() {
    return this._storeService.hasModification;
  }

  constructor(private _storeService: StoreService) {}

  public ngOnInit() {
    this.isOpen = this.number === 0 || this.number === 1;
  }

  public toggleBloc() {
    this.isOpen = !this.isOpen;
  }
}
