import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationModal } from '@src/components/modals/confirmation/confirmation.modal';
import { ArlenorCharacter } from '@src/models/arlenor/ArlenorCharacter';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'character-creation-view',
  templateUrl: './character-creation.view.html',
  styles: [':host { display: contents; }'],
})
export class CharacterCreationView implements OnInit {
  @Output() public outReset = new EventEmitter();
  @Output() public outFinish = new EventEmitter();

  public form: FormGroup;
  public hasLevel: boolean;
  public isInvalid: boolean;

  public get character(): ArlenorCharacter {
    return this._storeService.character;
  }

  public get isModified() {
    return this._storeService.hasModification;
  }

  constructor(
    private _dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      race: new FormControl(null, Validators.required),
      caracts: new FormControl(null, Validators.required),
      crystals01: new FormControl(null, Validators.required),
      crystals02: new FormControl(null, Validators.required),
      skills: new FormControl(null, Validators.required),
      divinity: new FormControl(null, Validators.required),
      identity: new FormControl(null, Validators.required),
      details: new FormControl(null, Validators.required),
    });
  }

  public ngOnInit() {
    this.hasLevel = false;
    this._storeService.modifyCharacter(false);
  }

  public confirmLevel() {
    this.hasLevel = true;
    if (this.character.numLevel === 1) this.form.controls.crystals02.setValue(true);
    window.scroll(0, 0);
  }

  public check(key: string, value: boolean) {
    this.form.controls[key].setValue(value ? true : null);
  }

  public reset() {
    if (this.isModified) {
      const dialogRef = this._dialog.open(ConfirmationModal, {
        data: {
          title: `Changement de page`,
          content: `Vous allez quitter la page : les modifications ne seront pas enregistrées.`,
        },
      });

      dialogRef.afterClosed().subscribe((value: boolean) => {
        if (value) this.outReset.emit();
      });
    } else this.outReset.emit();
  }

  public finish() {
    this.outFinish.emit();
  }
}
