<div class="card">
  @for (rank of ranks; track rank.Code; let isFirst = $first) {
    <hr *ngIf="!isFirst" class="u-marginTop2 u-marginBottom1" />
    <div class="u-flexRow u-justifyBetween u-marginBottom1">
      <h3>Pouvoirs de rang {{ rank.Libelle }}</h3>
      <h3 *ngIf="currentRanks[rank.Code].max">
        ({{ currentRanks[rank.Code].current }} sur {{ currentRanks[rank.Code].max }})
      </h3>
    </div>
    <div class="u-flexRow u-flexWrap">
      <button
        *ngFor="let power of rankPowers[rank.Code]"
        mat-stroked-button
        class="character-powers-selection-button"
        [disabled]="checkDisabled(rank.Code, power)"
        [color]="checkPower(power) ? 'primary' : ''"
        (click)="changePower(power)"
      >
        <div class="character-powers-selection-button-hover">
          <h2 class="u-marginTop0 u-marginBottom0">{{ power.name }}</h2>
          <b>{{ power.type.Libelle }}</b>
          <br />
          <br />
          <i>
            <i class="icon-spinner"></i>&nbsp;&nbsp;{{ power.category.Libelle }}
            &nbsp;•&nbsp;
            <i class="icon-enlarge"></i>&nbsp;&nbsp;{{ power.range.Libelle }}
            &nbsp;•&nbsp;
            <i class="icon-hour-glass"></i>&nbsp;&nbsp;{{ power.duration.Libelle }}
          </i>
          <br />
          <br />
          {{ power.description ? power.description : 'Aucune description disponible' }}
        </div>
        <img
          class="character-powers-selection-button-img"
          [src]="power.image"
          [alt]="power.type.Libelle"
          [title]="power.type.Libelle"
        />
        {{ power.name }}
        <mat-checkbox
          [disabled]="checkDisabled(rank.Code, power)"
          [color]="checkPower(power) ? 'primary' : ''"
          [checked]="checkPower(power)"
        ></mat-checkbox>
      </button>
    </div>
  }
</div>

<div *ngIf="powers.length === 0" class="card is-info u-textCenter">Aucun pouvoir.</div>
