<creature-template [number]="3" label="Choix des pouvoirs" [isInvalid]="!form.valid">
  <form [formGroup]="form" class="u-flexColumn">
    <div class="u-flexRow u-alignCenter">
      <mat-form-field>
        <mat-label>Tri par classe</mat-label>
        <mat-select formControlName="codeSpeciality">
          <mat-select-trigger>{{ selectedSpeName }}</mat-select-trigger>
          <mat-option *ngFor="let spe of allSpecialities" [value]="spe.code">
            <span [class]="'status-' + spe.color">
              <span class="u-marginRight05" [class]="spe.role.icon"></span>
              <span>{{ spe.role.name }}</span>
            </span>
            {{ spe.name }}
            <i>{{ getLibelleRank(spe.code) }}</i>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button type="button" mat-icon-button color="primary" (click)="openSpePopup()">?</button>
    </div>

    @if (nbPowers === maxPowers) {
      <div class="card">Tous les pouvoirs possibles ont été sélectionnés.</div>
    } @else {
      <div class="card">
        <p class="u-marginBottom025">
          Pouvoirs sélectionnés : <b>{{ nbPowers }} sur {{ maxPowers }}</b> possibles.
        </p>
        <ul>
          @for (power of powers; track power.id; let isLast = $last) {
            <li>
              <b>{{ power.name }}</b>
            </li>
          }
        </ul>
      </div>
    }

    <creature-powers-selection
      *ngIf="selectedSpeciality"
      [idsPowers]="idsPowers"
      [powers]="filteredPowers"
      [disabled]="nbPowers === maxPowers"
      (outAdd)="addPower($event)"
      (outRemove)="removePower($event)"
    ></creature-powers-selection>
  </form>
</creature-template>
