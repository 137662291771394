<creature-template [number]="4" label="Choix de l'identité" [isInvalid]="!form.valid">
  <form [formGroup]="form" class="u-flexColumn">
    <div>
      <label for="avatar" class="creature-identity-avatar u-marginTop05">
        <span *ngIf="!form.controls.avatar.value">
          Avatar de la créature<br /><br />Importer une image<br />(ratio 1:1 | JPG, PNG | max 1 Mo)
        </span>
        <img
          *ngIf="form.controls.avatar.value"
          alt="Avatar de la créature"
          [src]="getProfil(form.controls.avatar.value)"
        />
      </label>
      <input type="file" name="avatar" id="avatar" accept="image/png, image/jpeg" (change)="changeAvatar($event)" />
    </div>

    <mat-form-field>
      <mat-label>Nom de la créature</mat-label>
      <input type="text" matInput maxlength="25" formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Description (physique)</mat-label>
      <input
        type="text"
        matInput
        maxlength="35"
        placeholder="Robuste, élégant, frêle, ridé, jeune..."
        formControlName="description"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Personnalité (caractère)</mat-label>
      <input
        type="text"
        matInput
        maxlength="35"
        placeholder="Timide, sérieux, charmeur, rêveur, généreux..."
        formControlName="traits"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Histoire personnelle et faits divers</mat-label>
      <textarea
        matInput
        maxlength="740"
        placeholder="Son enfance, sa motivation dans la vie, l'acquisition de ses pouvoirs, des haut-faits réalisés..."
        formControlName="story"
      >
      </textarea>
    </mat-form-field>
  </form>
</creature-template>
