export class PageTitles {
  public static about = `À propos`;
  public static academy = `L'Académie des Mages`;
  public static adventure = `L'Aventure`;
  public static answers = `Les Réponses`;
  public static avatar = `La Création d'Avatar`;
  public static calendar = `Le Calendrier`;
  public static celestia = `L'Île Célestia`;
  public static chronology = `La Chronologie Célestienne`;
  public static character = `La Création de Perso`;
  public static creature = `La Création de Créature`;
  public static crystals = `Les Cristaux évolutifs`;
  public static encyclopedia = `L'Encyclopédie`;
  public static etincelles = `Les Étincelles de Célestia`;
  public static home = `Le Monde d'Arlénor`;
  public static kitui = `Kit UI`;
  public static legacy = `Mentions légales`;
  public static magic = `La Magie des Cristaux`;
  public static races = `Les Races`;
  public static religion = `La Religion`;
  public static roleplay = `Le Jeu de Rôle`;
  public static system = `Le Système de Jeu`;
  public static universe = `L'Univers`;
  public static worldmap = `La Carte du Monde`;
}

export class PageSubtitles {
  public static encycloBestiary = `Bestiaire`;
  public static encycloBestiaryAnimals = `Animaux`;
  public static encycloBestiaryMagics = `Créatures magiques`;
  public static encycloBestiaryMonsters = `Monstres`;
  public static encycloBestiaryDivinities = `Créatures divines`;
  public static encycloDivine = `Divinités`;
  public static encycloCoalition = `Coalition`;
  public static encycloSocieteEveil = `Société de l'Eveil`;
  public static encycloExpanse = `Expansion de l'Humanité`;
  public static encycloFactions = `Factions`;
  public static encycloFactionsOff = `Factions officielles`;
  public static encycloFactionsSec = `Factions secrètes`;
  public static encycloLands = `Territoires`;
  public static encycloFaradelLand = `Empire Faradélien`;
  public static encycloJirakanLand = `Régime Jirakanien`;
  public static encycloDakarosLand = `Royaume de Dakaros`;
  public static encycloEtherLand = `Bosquet d'Ether`;
  public static encycloAtlanticaLand = `Abysses d'Atlantica`;
  public static encycloSomniLand = `Terres Oubliées de Somni`;
  public static encycloNoLand = `Zones de non-droit`;
  public static encycloSecrets = `Secrets du Monde`;
  public static systemPerso = `Composantes d'un personnage`;
  public static systemCaracts = `Caractéristiques et valeurs dérivées`;
  public static systemRaces = `Races jouables`;
  public static systemSkills = `Compétences principales`;
  public static systemStuffs = `Équipements`;
  public static systemCrystals = `Cristaux évolutifs`;
  public static systemStory = `Finitions`;
  public static systemRules = `Règles du système`;
  public static systemDivine = `Paliers divins et morts`;
  public static systemDifficulties = `Tests et difficultés`;
  public static systemResults = `Réussites et échecs`;
  public static systemFight = `Combat et soins`;
  public static systemAlterations = `Altérations d'états`;
  public static systemExperience = `Expérience et niveaux`;
  public static systemJournaux = `Journaux de bord`;
  public static systemTeamSatan = `Team Satan`;
  public static systemTeamGabriel = `Team Gabriel`;
  public static systemTeamAsmodee = `Team Asmodée`;
  public static systemTeamMichael = `Team Michaël`;
  public static systemTeamLilith = `Team Lilith`;
}
