import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { CaractModal } from '@src/components/modals/caract/caract.modal';
import { ArlenorCharacter } from '@src/models/arlenor/ArlenorCharacter';
import { ArlenorLevel } from '@src/models/arlenor/ArlenorLevel';
import { ArlenorRace } from '@src/models/arlenor/ArlenorRace';
import { getListRaces } from '@src/models/data/ListRaces';
import { CaractDescriptionEnum, CaractNomEnum } from '@src/models/ModelEnum';
import { StoreService } from '@src/services/store.service';

interface CaractsElement {
  libelle: string;
  code: string;
  key: string;
  isNullable: boolean;
}

@Component({
  selector: 'character-caracts',
  templateUrl: './character-caracts.component.html',
  styles: [':host {	display: contents; }'],
})
export class CharacterCaractsComponent implements OnInit {
  @Output() public outCheck = new EventEmitter<boolean>();

  public data: CaractsElement[] = [
    {
      libelle: CaractNomEnum.Force.Libelle,
      code: CaractNomEnum.Force.Code,
      key: CaractNomEnum.Force.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Habilete.Libelle,
      code: CaractNomEnum.Habilete.Code,
      key: CaractNomEnum.Habilete.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Intellect.Libelle,
      code: CaractNomEnum.Intellect.Code,
      key: CaractNomEnum.Intellect.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Tenacite.Libelle,
      code: CaractNomEnum.Tenacite.Code,
      key: CaractNomEnum.Tenacite.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Charisme.Libelle,
      code: CaractNomEnum.Charisme.Code,
      key: CaractNomEnum.Charisme.Code.toLowerCase(),
      isNullable: false,
    },
    {
      libelle: CaractNomEnum.Magie.Libelle,
      code: CaractNomEnum.Magie.Code,
      key: CaractNomEnum.Magie.Code.toLowerCase(),
      isNullable: true,
    },
  ];
  public dataSource = new MatTableDataSource<CaractsElement>(this.data);

  public caractDescriptionEnum = CaractDescriptionEnum;
  public allRaces: ArlenorRace[];
  public level: ArlenorLevel;
  public codeRace: string;
  public totalCaracts: number;
  public maxCaract: number;
  public form: FormGroup;

  private _destroyRef = inject(DestroyRef);

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      for: new FormControl(1),
      hab: new FormControl(1),
      int: new FormControl(1),
      cha: new FormControl(1),
      ten: new FormControl(1),
      mag: new FormControl(1),
      pointsLeft: new FormControl(0),
    });
  }

  public ngOnInit() {
    this.allRaces = getListRaces();
    this.level = this._storeService.character.level;
    this.codeRace = this._storeService.character.codeRace;
    this.maxCaract = this._storeService.character.numLevel >= 10 ? 5 : 4;

    // Init
    this.form.controls.for.setValue(this._storeService.character.caractFor);
    this.form.controls.hab.setValue(this._storeService.character.caractHab);
    this.form.controls.int.setValue(this._storeService.character.caractInt);
    this.form.controls.cha.setValue(this._storeService.character.caractCha);
    this.form.controls.ten.setValue(this._storeService.character.caractTen);
    this.form.controls.mag.setValue(this._storeService.character.caractMag);

    this._updatePointsLeft();

    this.outCheck.emit(this.form.controls.pointsLeft.value === 0);
    this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this._storeService.modifyCharacter();
      this.outCheck.emit(this.form.controls.pointsLeft.value === 0);
      this._save();
    });
  }

  public getInitiative() {
    return parseInt(this.form.controls.hab.value) + parseInt(this.form.controls.int.value);
  }

  public getPointsDeVie() {
    let points = this.level.maxHealth;
    if (this.codeRace === this.allRaces[1].code) points++;
    if (this.codeRace === this.allRaces[4].code) points++;
    if (parseInt(this.form.controls.ten.value) === 1) points--;
    else if (parseInt(this.form.controls.ten.value) > 3) points++;
    return points;
  }

  public changeCaract() {
    this._updatePointsLeft();
  }

  public openCaractPopup(caract: string) {
    this.dialog.open(CaractModal, {
      data: caract,
    });
  }

  private _updatePointsLeft() {
    const totalCaracts =
      parseInt(this.form.controls.for.value) +
      parseInt(this.form.controls.hab.value) +
      parseInt(this.form.controls.int.value) +
      parseInt(this.form.controls.ten.value) +
      parseInt(this.form.controls.cha.value) +
      parseInt(this.form.controls.mag.value);
    this.form.controls.pointsLeft.setValue(this.level.maxCaracts - totalCaracts);
  }

  private _save() {
    const newCharacter = new ArlenorCharacter();
    newCharacter.caractFor = parseInt(this.form.controls.for.value);
    newCharacter.caractHab = parseInt(this.form.controls.hab.value);
    newCharacter.caractInt = parseInt(this.form.controls.int.value);
    newCharacter.caractTen = parseInt(this.form.controls.ten.value);
    newCharacter.caractCha = parseInt(this.form.controls.cha.value);
    newCharacter.caractMag = parseInt(this.form.controls.mag.value);
    this._storeService.changeCharacterCaracts(newCharacter);
  }
}
