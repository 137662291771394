<div class="creature-resume card">
  <img class="creature-resume-img" [src]="creature.profil" [alt]="creature.name" />
  <h2 class="u-textEllipsis u-marginTop1 u-marginBottom05">{{ creature.name || 'Inconnu' }}</h2>
  <p class="u-marginBottom0">
    <span [class]="creature.getDifficultyColor(allPowers)">{{ creature.getDifficultyLibelle(allPowers) }}</span>
  </p>
  <div class="u-flexColumn u-marginTop1">
    <div>
      <p class="u-marginBottom025">Type de créature</p>
      <p class="u-marginBottom0">
        <b>{{ creature.bestiary?.name || '-' }}</b>
      </p>
    </div>
    <div>
      <p class="u-marginBottom025">Caractéristiques</p>
      <div class="u-flexRow">
        <div class="u-flexColumn u-gap0">
          <p class="u-flexRow u-justifyBetween u-marginBottom0">
            <span>FOR</span>
            <span>
              <b>{{ creature.caractFor || '-' }}</b>
            </span>
          </p>
          <p class="u-flexRow u-justifyBetween u-marginBottom0">
            <span>HAB</span>
            <span>
              <b>{{ creature.caractHab || '-' }}</b>
            </span>
          </p>
          <p class="u-flexRow u-justifyBetween u-marginBottom0">
            <span>INT</span>
            <span>
              <b>{{ creature.caractInt || '-' }}</b>
            </span>
          </p>
        </div>
        <div class="u-flexColumn u-gap0">
          <p class="u-flexRow u-justifyBetween u-marginBottom0">
            <span>TEN</span>
            <span>
              <b>{{ creature.caractTen || '-' }}</b>
            </span>
          </p>
          <p class="u-flexRow u-justifyBetween u-marginBottom0">
            <span>CHA</span>
            <span>
              <b>{{ creature.caractCha || '-' }}</b>
            </span>
          </p>
          <p class="u-flexRow u-justifyBetween u-marginBottom0">
            <span>MAG</span>
            <span>
              <b>{{ creature.caractMag || '-' }}</b>
            </span>
          </p>
        </div>
      </div>
    </div>
    <div>
      <p class="u-marginBottom025">Pouvoirs</p>
      <p class="u-marginBottom0">
        <b *ngIf="!powers.length">-</b>
      </p>
      <ul>
        @for (power of powers; track power.id; let isLast = $last) {
          <li>
            <b>{{ power.name }}</b>
          </li>
        }
      </ul>
    </div>
  </div>
</div>
