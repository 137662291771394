import { Component, DestroyRef, EventEmitter, inject, OnChanges, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { SpecialityModal } from '@src/components/modals/speciality/speciality.modal';
import { ArlenorCreature } from '@src/models/arlenor/ArlenorCreature';
import { ArlenorPower } from '@src/models/arlenor/ArlenorPower';
import { ArlenorSpeciality } from '@src/models/arlenor/ArlenorSpeciality';
import { ArlenorSpecialities } from '@src/models/data/ListSpecialities';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'creature-powers',
  templateUrl: './creature-powers.component.html',
  styles: [':host {	display: contents; }'],
})
export class CreaturePowersComponent implements OnInit, OnChanges {
  @Output() public outCheck = new EventEmitter<boolean>();

  public form: FormGroup;
  public creature: ArlenorCreature;
  public allSpecialities: ArlenorSpeciality[];
  public idsPowers: number[];

  public nbPowers: number;
  public maxPowers: number;

  private _destroyRef = inject(DestroyRef);

  public get selectedSpeciality(): ArlenorSpeciality | null {
    if (!this.form.controls.codeSpeciality.value) return null;
    return ArlenorSpecialities.getSpeciality(this.form.controls.codeSpeciality.value);
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  public get filteredPowers(): ArlenorPower[] {
    const filteredPowers = this.allPowers.filter(power => power.speciality.code === this.selectedSpeciality?.code);
    filteredPowers.sort((a, b) => a.name.localeCompare(b.name));
    return filteredPowers;
  }

  public get powers(): ArlenorPower[] {
    const powers = this.allPowers.filter(power => this.idsPowers.includes(power.id));
    powers.sort((a, b) => a.name.localeCompare(b.name));
    return powers;
  }

  public get selectedSpeName() {
    const codeSpeciality = this.form.controls.codeSpeciality.value;
    if (codeSpeciality) return this.allSpecialities.find(spe => spe.code === codeSpeciality).name;
    else return '-';
  }

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      codeSpeciality: new FormControl(null),
    });
  }

  public ngOnInit() {
    this.creature = this._storeService.creature;
    this.idsPowers = this.creature.idsPowers;
    this.allSpecialities = ArlenorSpecialities.getListSpecialities();

    // Init
    this.maxPowers = 5;
    this.form.controls.codeSpeciality.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this._checkNbPowers();
    });

    this._checkOldPowers();
    this.outCheck.emit(!this.form.invalid);
  }

  public ngOnChanges() {
    this._checkOldPowers();
  }

  public addPower(power: ArlenorPower) {
    this.idsPowers.push(power.id);
    this._storeService.modifyCreature();
    this._checkNbPowers();
    this._save();
  }

  public removePower(power: ArlenorPower) {
    this.idsPowers = this.idsPowers.filter((idPower: number) => idPower !== power.id);
    this._storeService.modifyCreature();
    this._checkNbPowers();
    this._save();
  }

  public getLibelleRank(codeSpeciality: string) {
    const powers = this.allPowers.filter(power => this.idsPowers.includes(power.id) && power.codeSpeciality === codeSpeciality);
    if (!powers.length) return '';
    else if (powers.length === 1) return '(1 sélectionné)';
    else return `(${powers.length} sélectionnés)`;
  }

  public openSpePopup() {
    this.dialog.open(SpecialityModal, {
      data: this.selectedSpeciality ? this.selectedSpeciality : null,
    });
  }

  public openSpeURL() {
    const url = this._router.serializeUrl(this._router.createUrlTree(['jeu-de-roles/cristaux'], { queryParams: { spe: this.selectedSpeciality.code } }));
    window.open(url, '_blank');
  }

  private _checkOldPowers() {
    if (!this.allPowers || !this.idsPowers) return;
    const validIdsPowers = this.allPowers.map(power => power.id);
    this.idsPowers = this.idsPowers.filter((idPower: number) => validIdsPowers.includes(idPower));
    this._checkNbPowers();
  }

  private _checkNbPowers() {
    if (!this.allPowers || !this.idsPowers) return;
    this.nbPowers = this.allPowers.filter(power => this.idsPowers.includes(power.id)).length;
  }

  private _save() {
    const newCreature = new ArlenorCreature();
    newCreature.idsPowers = this.idsPowers;
    this._storeService.changeCreatureCrystals(newCreature);
  }
}
