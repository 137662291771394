import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AnimFinishComponent } from '@src/components/anim-finish/anim-finish.component';
import { AppFooterComponent } from '@src/components/app-footer/app-footer.component';
import { AppHeaderComponent } from '@src/components/app-header/app-header.component';
import { AppPopupComponent } from '@src/components/app-popup/app-popup.component';
import { ArrowButtonComponent } from '@src/components/arrow-button/arrow-button.component';
import { CelestiaQuestionComponent } from '@src/components/celestia-question/celestia-question.component';
import { CharacterCaractsComponent } from '@src/components/character/caracts/character-caracts.component';
import { CharacterCrystalsComponent } from '@src/components/character/crystals/character-crystals.component';
import { CharacterPowersSelectionComponent } from '@src/components/character/crystals/selection/character-powers-selection.component';
import { CharacterDetailsComponent } from '@src/components/character/details/character-details.component';
import { CharacterDivinityComponent } from '@src/components/character/divinity/character-divinity.component';
import { CharacterIdentityComponent } from '@src/components/character/identity/character-identity.component';
import { CharacterLevelComponent } from '@src/components/character/level/character-level.component';
import { CharacterRaceComponent } from '@src/components/character/race/character-race.component';
import { CharacterResumeComponent } from '@src/components/character/resume/character-resume.component';
import { CharacterSkillsComponent } from '@src/components/character/skills/character-skills.component';
import { CharacterTemplateComponent } from '@src/components/character/template/character-template.component';
import { CreatureBestiaryComponent } from '@src/components/creature/bestiary/creature-bestiary.component';
import { CreatureCaractsComponent } from '@src/components/creature/caracts/creature-caracts.component';
import { CreatureIdentityComponent } from '@src/components/creature/identity/creature-identity.component';
import { CreaturePowersComponent } from '@src/components/creature/powers/creature-powers.component';
import { CreaturePowersSelectionComponent } from '@src/components/creature/powers/selection/creature-powers-selection.component';
import { CreatureResumeComponent } from '@src/components/creature/resume/creature-resume.component';
import { CreatureTemplateComponent } from '@src/components/creature/template/creature-template.component';
import { ExpandBlocComponent } from '@src/components/expand-bloc/expand-bloc.component';
import { FooterLinkComponent } from '@src/components/footer-link/footer-link.component';
import { FooterSectionComponent } from '@src/components/footer-section/footer-section.component';
import { GlypheComponent } from '@src/components/glyphe/glyphe.component';
import { HeadLayoutComponent } from '@src/components/head-layout/head-layout.component';
import { HeadSectionComponent } from '@src/components/head-section/head-section.component';
import { MaterialsModule } from '@src/components/materials.modules';
import { MeikerComponent } from '@src/components/meiker/meiker.component';
import { CaractModal } from '@src/components/modals/caract/caract.modal';
import { ConfirmationModal } from '@src/components/modals/confirmation/confirmation.modal';
import { DivinityModal } from '@src/components/modals/divinity/divinity.modal';
import { FactionModal } from '@src/components/modals/faction/faction.modal';
import { InformationModal } from '@src/components/modals/information/information.modal';
import { PowerFormModal } from '@src/components/modals/power-form/power-form.modal';
import { PowerModal } from '@src/components/modals/power/power.modal';
import { RaceModal } from '@src/components/modals/race/race.modal';
import { SkillModal } from '@src/components/modals/skill/skill.modal';
import { SpecialityModal } from '@src/components/modals/speciality/speciality.modal';
import { StuffModal } from '@src/components/modals/stuff/stuff.modal';
import { UserFormModal } from '@src/components/modals/user-form/user-form.modal';
import { PowerImageComponent } from '@src/components/power-image/power-image.component';
import { SkillsSelectionComponent } from '@src/components/skills-selection/skills-selection.component';
import { CharactersTableComponent } from '@src/components/tables/characters/characters-table.component';
import { DivinitiesTableComponent } from '@src/components/tables/divinities/divinities-table.component';
import { PowersTableComponent } from '@src/components/tables/powers/powers-table.component';
import { PowersSelectionTableComponent } from '@src/components/tables/powers/selection/powers-selection-table.component';
import { SkillsSelectionTableComponent } from '@src/components/tables/skills/selection/skills-selection-table.component';
import { SkillsTableComponent } from '@src/components/tables/skills/skills-table.component';
import { StuffsTableComponent } from '@src/components/tables/stuffs/stuffs-table.component';
import { ToggleButtonComponent } from '@src/components/toggle-button/toggle-button.component';
import { TreeMenuComponent } from '@src/components/tree-menu/tree-menu.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, RouterModule, MaterialsModule],
  declarations: [
    AnimFinishComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppPopupComponent,
    ArrowButtonComponent,
    CaractModal,
    CelestiaQuestionComponent,
    CharacterCaractsComponent,
    CharacterCrystalsComponent,
    CharacterDetailsComponent,
    CharacterDivinityComponent,
    CharacterIdentityComponent,
    CharacterLevelComponent,
    CharacterPowersSelectionComponent,
    CharacterRaceComponent,
    CharacterResumeComponent,
    CharacterSkillsComponent,
    CharacterTemplateComponent,
    CharactersTableComponent,
    CreatureBestiaryComponent,
    CreatureCaractsComponent,
    CreatureIdentityComponent,
    CreaturePowersComponent,
    CreaturePowersSelectionComponent,
    CreatureResumeComponent,
    CreatureTemplateComponent,
    ConfirmationModal,
    DivinitiesTableComponent,
    DivinityModal,
    ExpandBlocComponent,
    FactionModal,
    FooterLinkComponent,
    FooterSectionComponent,
    GlypheComponent,
    HeadLayoutComponent,
    HeadSectionComponent,
    InformationModal,
    MeikerComponent,
    PowerImageComponent,
    PowerModal,
    PowerFormModal,
    PowersSelectionTableComponent,
    PowersTableComponent,
    RaceModal,
    SkillModal,
    SkillsSelectionComponent,
    SkillsSelectionTableComponent,
    SkillsTableComponent,
    SpecialityModal,
    StuffModal,
    StuffsTableComponent,
    ToggleButtonComponent,
    TreeMenuComponent,
    UserFormModal,
  ],
  exports: [
    AnimFinishComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppPopupComponent,
    ArrowButtonComponent,
    CaractModal,
    CelestiaQuestionComponent,
    CharacterCaractsComponent,
    CharacterCrystalsComponent,
    CharacterDetailsComponent,
    CharacterDivinityComponent,
    CharacterIdentityComponent,
    CharacterLevelComponent,
    CharacterPowersSelectionComponent,
    CharacterRaceComponent,
    CharacterResumeComponent,
    CharacterSkillsComponent,
    CharacterTemplateComponent,
    CharactersTableComponent,
    CreatureBestiaryComponent,
    CreatureCaractsComponent,
    CreatureIdentityComponent,
    CreaturePowersComponent,
    CreaturePowersSelectionComponent,
    CreatureResumeComponent,
    CreatureTemplateComponent,
    ConfirmationModal,
    DivinitiesTableComponent,
    DivinityModal,
    ExpandBlocComponent,
    FactionModal,
    FooterLinkComponent,
    FooterSectionComponent,
    GlypheComponent,
    HeadLayoutComponent,
    HeadSectionComponent,
    InformationModal,
    MeikerComponent,
    PowerImageComponent,
    PowerModal,
    PowerFormModal,
    PowersSelectionTableComponent,
    PowersTableComponent,
    RaceModal,
    SkillModal,
    SkillsSelectionComponent,
    SkillsSelectionTableComponent,
    SkillsTableComponent,
    SpecialityModal,
    StuffModal,
    StuffsTableComponent,
    ToggleButtonComponent,
    TreeMenuComponent,
    UserFormModal,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
